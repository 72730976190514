import { ApiParams } from '@/hooks/useApi';
import { ConnectionAssurance } from '@/types';
import { AxiosResponse } from 'axios';

import appConfig from '../../app.config';

type GetConnectionAssuranceParams = {
    connectionAssuranceId: string;
};

export default async ({
    params,
    customerId,
    axios,
}: ApiParams<GetConnectionAssuranceParams>): Promise<ConnectionAssurance> => {
    const contractResponse: AxiosResponse<ConnectionAssurance> = await axios.get<ConnectionAssurance>(
        `${appConfig.services.contractApi}/customer/${customerId}/connection-assurance/${params.connectionAssuranceId}`,
    );

    if (contractResponse.status === 200) {
        return contractResponse.data;
    }

    throw new Error(
        `Failed to get connection-assurance with customer id ${customerId} and id ${params.connectionAssuranceId}`,
    );
};

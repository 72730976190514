// eslint-disable-next-line prefer-destructuring, @typescript-eslint/dot-notation
const env = window['env'];

const appConfig = {
    tenantApplicationClientId: env.TENANT_APPLICATION_CLIENT_ID,
    tenantName: env.TENANT_NAME,
    tenantDomain: `${env.TENANT_NAME}.onmicrosoft.com`,
    userFlowName: env.AUTH_FLOW_NAME,
    services: {
        contractApi: '/contract-api',
        connectionUserUploadApi: `${window.location.protocol}//${window.location.host}/contract-user-upload-api`,
        gisMiddlewareApi: '/gis-api',
        gisMiddlewareApiKey: env.DIG_APP_API_KEY_MIDDLEWARE_GIS,
        installerApi: '/installer-api',
    },
};

export default appConfig;

import { CommissioningData } from '@/connection-assurance/commissioning/types/CommissioningData';
import actions from '@/connection-assurance/commissioning/wizard/actions';
import { ALL_STEPS, STEPS } from '@/connection-assurance/commissioning/wizard/constants';
import { SYNC_STATE_WITH_LOCATION, UserEvent } from '@/connection-assurance/commissioning/wizard/events';
import guards from '@/connection-assurance/commissioning/wizard/guards';
import { getRoutes } from '@/connection-assurance/commissioning/wizard/util';
import {
    CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION,
    CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD,
    CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR,
    CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE,
} from '@/routes';
import { ConnectionAssurance } from '@/types';
import { debounce } from '@/utils/debounce';
import { formatNumber } from '@/utils/formatNumber';
import { Step } from '@ten-netzkundenportal/ui-components';
import { useMachine } from '@xstate/react';
import H from 'history';
import * as React from 'react';
import { generatePath, matchPath } from 'react-router-dom';
import { AnyStateMachine, Interpreter, StateFrom, createMachine } from 'xstate';

import { Context } from './context';
import { getConfig } from './machine';

export const getLocalStorageEntryName = (connectionAssuranceId: string) => {
    const localStoragePrefix = 'einspeiser-inbetriebsetzung';
    return `${localStoragePrefix}-${connectionAssuranceId}`;
};

export const createInitialContext = (
    connectionAssurance: ConnectionAssurance,
): Pick<
    CommissioningData,
    | 'generatorData'
    | 'storageData'
    | 'plantComponentsGenerator'
    | 'plantComponentsStorage'
    | 'electricityMeterSerialNumber'
> => {
    const copiedConnectionAssurance = JSON.parse(JSON.stringify(connectionAssurance));

    return {
        generatorData: copiedConnectionAssurance.generatorData
            ? {
                  solarModuleCapacityInkWp: formatNumber(
                      copiedConnectionAssurance.generatorData.solarModuleCapacityInkWp,
                      1,
                      3,
                  ),
                  maStRGenerationPlant: copiedConnectionAssurance.generatorData.maStRGenerationPlant,
              }
            : undefined,
        plantComponentsGenerator: copiedConnectionAssurance.plantComponentsGenerator,
        plantComponentsStorage: copiedConnectionAssurance.plantComponentsStorage,
        storageData: copiedConnectionAssurance.storageData
            ? {
                  energyStorageCapacityInkWh: formatNumber(
                      copiedConnectionAssurance.storageData.energyStorageCapacityInkWh,
                      1,
                      3,
                  ),
                  maStRGeneratorStorage: copiedConnectionAssurance.storageData.maStRGeneratorStorage,
              }
            : undefined,
        electricityMeterSerialNumber: copiedConnectionAssurance.connectionDataProperties.electricityMeterSerialNumber,
    };
};

const createInitialSteps = (history: H.History, connectionAssurance: ConnectionAssurance) => {
    const processFunction = (path) => () => {
        history.push(path);
    };

    const step1: Step = {
        label: 'Erzeugungsanlage',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: ['Erzeugungsanlage', 'Energiespeicher'],
        processName: STEPS.GENERATION_PLANT,
        visible: [
            guards.isPlantComponentsGeneratorShown(connectionAssurance),
            guards.isPlantComponentsStorageShown(connectionAssurance),
        ],
    };

    const step2: Step = {
        label: 'Dokumente',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: ['Dokumentenupload'],
        processName: STEPS.DOCUMENT_UPLOAD,
        visible: [true],
    };

    const step3: Step = {
        label: 'Abschlussbestätigung',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: ['Abschlussbestätigung'],
        processName: STEPS.COMPLETION_CONFIRMATION,
        visible: [true],
    };

    const step4: Step = {
        label: 'Nächste Schritte',
        processFunctions: [],
        steplabels: [],
        processName: STEPS.NEXT_STEPS,
        visible: [true],
    };

    return [step1, step2, step3, step4];
};

export const createWizardMachine = (
    history: H.History,
    currentPath: string,
    connectionAssurance: ConnectionAssurance,
) => {
    const config = getConfig(connectionAssurance.id);
    const routes = getRoutes(config).map(([nodePath, path]) => ({
        target: `${nodePath.join('.')}`,
        cond: (context, event) =>
            event.path && matchPath(event.path, { path, exact: true, strict: false }) && context.meta.path !== path,
    }));
    const rehydratedConfig = JSON.parse(localStorage.getItem(getLocalStorageEntryName(connectionAssurance.id))) || {
        meta: { furthestStep: { furthestProcess: ALL_STEPS[0], furthestSubprocess: 1 } },
        ...createInitialContext(connectionAssurance),
    };
    const machine = {
        ...config,
        ...{
            context: {
                ...rehydratedConfig,
                ...{ meta: { ...rehydratedConfig.meta, ...{ history, path: currentPath } } },
            },
        },
    };
    machine.on[SYNC_STATE_WITH_LOCATION] = routes;
    machine.context.meta.steps = createInitialSteps(history, connectionAssurance);

    return createMachine<Partial<Context>, UserEvent>(machine, { guards, actions });
};

export default (
    history: H.History,
    path: string,
    connectionAssurance: ConnectionAssurance,
): [
    StateFrom<AnyStateMachine>,
    Interpreter<Partial<Context>, unknown, UserEvent>['send'],
    Interpreter<Partial<Context>, unknown, UserEvent>,
] => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const machine: AnyStateMachine = React.useMemo(() => createWizardMachine(history, path, connectionAssurance), []);
    const [state, send, service] = useMachine(machine);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLocalStorage = React.useCallback(
        debounce((context: Partial<Context>) => {
            const storedContext = {
                ...context,
                ...{
                    meta: {
                        ...context.meta,
                        ...{ steps: {} },
                    },
                },
            };
            localStorage.setItem(getLocalStorageEntryName(connectionAssurance.id), JSON.stringify(storedContext));
        }, 500),
        [],
    );

    React.useEffect(() => {
        updateLocalStorage(state.context);
    }, [state.context, updateLocalStorage]);

    return [state, send, service];
};

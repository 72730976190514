import useApi from '@/hooks/useApi';
import { DASHBOARD } from '@/routes';
import { APIRequestState } from '@/types/Api';
import { TechnicalOperationsManagerInformation } from '@/types/technicalOperationsManager';
import useConnectionAssurance from '@/utils/useConnectionAssurance';
import TechnicalOperationsManagerDetailForm from '@components/technical-operations-manager/TechnicalOperationsManagerDetailForm';
import {
    CenteredLoadingSpinner,
    ErrorBox,
    Form,
    FormLayout,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { navigateToUrl } from 'single-spa';

import updateTechnicalOperationsManager from './api/updateTechnicalOperationsManager';

interface Props {
    connectionAssuranceId: string;
}

const TechnicalOperationsManagerUpdate = ({ connectionAssuranceId }: Props) => {
    const methods = useForm<TechnicalOperationsManagerInformation>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            technicalOperationsManager: {
                contact: {
                    telephone: {
                        countryPrefix: '49',
                        type: 'Mobile',
                    },
                },
            },
        },
    });
    const {
        control,
        setValue,
        formState: { isValid, isSubmitting },
    } = methods;

    const { connectionAssurance, isLoading } = useConnectionAssurance(connectionAssuranceId);
    const [updateApiState, setUpdateApiState] = React.useState<APIRequestState>('initial');
    const updateTechnicalOperationsManagerApi = useApi(updateTechnicalOperationsManager);

    const technicalOperationsManager = useWatch({
        control,
        name: 'technicalOperationsManager',
    });

    React.useEffect(() => {
        if (connectionAssurance && connectionAssurance.plantData.technicalOperationsManager) {
            setValue('technicalOperationsManager', connectionAssurance.plantData.technicalOperationsManager);
        }
    }, [connectionAssurance, setValue]);

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        onBack: () => navigateToUrl(DASHBOARD),
        onNext: () => {
            setUpdateApiState('loading');
            updateTechnicalOperationsManagerApi({ technicalOperationsManager, connectionAssuranceId })
                .then(() => {
                    setUpdateApiState('finished_successfully');
                    navigateToUrl(DASHBOARD);
                })
                .catch((err) => {
                    setUpdateApiState('error');
                    console.error('Failed to update technical operations manager', err);
                });
        },
        isNextValid: isValid && !isSubmitting,
        nextLabel: 'Speichern',
        prevLabel: 'Abbrechen',
        loading: updateApiState === 'loading',
    });

    if (isLoading) {
        return <CenteredLoadingSpinner />;
    }

    return (
        connectionAssurance && (
            <Form
                title="Technischer Betriebsführer"
                leftButton={prevButton}
                rightButton={nextButton}
                isPositionRelative
            >
                <FormProvider {...methods}>
                    <FormLayout>
                        <TechnicalOperationsManagerDetailForm />
                    </FormLayout>
                </FormProvider>
                {updateApiState === 'error' && (
                    <div className="absolute left-3 bottom-10">
                        <ErrorBox onClick={() => setUpdateApiState('initial')} />
                    </div>
                )}
            </Form>
        )
    );
};

export default TechnicalOperationsManagerUpdate;

import {
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX,
    CONNECTION_ASSURANCE_COMPENSATION_COMPLETION,
    CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD,
    CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
    CONNECTION_ASSURANCE_COMPENSATION_NEXT_STEPS,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA,
    DASHBOARD,
} from '@/routes';
import { ConnectionAssurance } from '@/types';
import { WizardStep } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { BankAccountDetails } from './bank-account-details/BankAccountDetails';
import { Compensation } from './compensation/Compensation';
import { Completion } from './completion/Completion';
import { DocumentUpload } from './document-upload/DocumentUpload';
import { ExistingGenerationPlant } from './existing-generation-plant/ExistingGenerationPlant';
import { ExistingSolarPlant } from './existing-solar-plant/ExistingSolarPlant';
import { GenerationPlantDataForm } from './generation-plant-data/GenerationPlantDataForm';
import { IncreasedCompensationForm } from './increased-compensation/IncreasedCompensationForm';
import { LandlordToTenantsElectricityBonusForm } from './landlord-to-tenants-electricity-bonus/LandlordToTenantsElectricityBonusForm';
import { LocationData } from './location-data/LocationData';
import { NextSteps } from './next-steps/NextSteps';
import { SalesTax } from './sales-tax/SalesTax';
import { StoragePlantData } from './storage-plant-data/StoragePlantData';
import { Meta } from './types/MetaObject';
import { SUBPROCESS, SubprocessValue } from './wizard/constants';
import { Context } from './wizard/context';
import { NEXT_EVENT, PREV_EVENT } from './wizard/events';
import { getFurthestStepsForWizard } from './wizard/getFurthestSteps';
import { getLocalStorageEntryName, useWizardMachine } from './wizard/wizard';

type Props = {
    connectionAssurance: ConnectionAssurance;
};

export const ConnectionAssuranceCompensationRouting = ({ connectionAssurance }: Props) => {
    const [state, send] = useWizardMachine(connectionAssurance);

    const updateContext = React.useCallback(
        (payload: Partial<Context>, resetStepToProcess?: SubprocessValue) => {
            const metaWithUpdatedFurthestStep: Meta | undefined = resetStepToProcess
                ? {
                      furthestProcessSubProcessIndex: SUBPROCESS[resetStepToProcess].processSubprocessIndex,
                      ...payload.meta,
                  }
                : undefined;

            if (metaWithUpdatedFurthestStep) {
                return send({
                    type: 'UPDATE_CONTEXT',
                    value: {
                        ...payload,
                        meta: metaWithUpdatedFurthestStep,
                    },
                });
            }
            return send({
                type: 'UPDATE_CONTEXT',
                value: payload,
            });
        },
        [send],
    );

    const onSubmit = React.useCallback(
        (payload: Partial<Context>) => {
            updateContext(payload);
            return send(NEXT_EVENT);
        },
        [send, updateContext],
    );

    const goBack = () => send(PREV_EVENT);

    const goToDashboard = () => {
        localStorage.removeItem(getLocalStorageEntryName(connectionAssurance.id));
        window.history.pushState({}, document.title, DASHBOARD);
    };

    const title = `Vergütungseinstufung (${connectionAssurance.processCommunicationId})`;

    return (
        <div className="flex flex-col gap-x-10 w-full gap-y-5">
            <div className="w-3/4 my-10 pl-30 mx-auto">
                <WizardStep
                    steps={state.context.meta.steps}
                    furthestStep={getFurthestStepsForWizard(state.context.meta.furthestProcessSubProcessIndex)}
                    hasError={state.value === 'nextSteps'}
                />
            </div>
            <div className="w-full" data-testid="routing-wrapperDiv">
                <Switch>
                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION}>
                        <Compensation
                            onSubmit={onSubmit}
                            goBack={goToDashboard}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA}>
                        <LocationData
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA}>
                        <GenerationPlantDataForm
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA}>
                        <StoragePlantData
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT}>
                        <ExistingGenerationPlant
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT}>
                        <ExistingSolarPlant
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS}>
                        <LandlordToTenantsElectricityBonusForm
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION}>
                        <IncreasedCompensationForm
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS}>
                        <BankAccountDetails
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX}>
                        <SalesTax
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD}>
                        <DocumentUpload
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_COMPLETION}>
                        <Completion
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMPENSATION_NEXT_STEPS}>
                        <NextSteps
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            connectionAssurance={connectionAssurance}
                            title={title}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

import { CommissioningData } from '@/connection-assurance/commissioning/types/CommissioningData';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { RadioButton } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';

type AcknowledgementSymmetryConditionProps = {
    register: UseFormRegister<CommissioningData>;
};

export const AcknowledgeSymmetryCondition = ({ register }: AcknowledgementSymmetryConditionProps) => (
    <>
        <p className="col-span-4 font-bold mt-6">Einhaltung der Symmetriebedingung</p>
        <p className="col-span-4 mb-6">
            Bitte wählen Sie nachfolgend eine der beiden aufgeführten Optionen für die Einhaltung der Symmetriebedingung
            aus:
        </p>

        <div className="col-span-3">
            Die Symmetriebedingung wird durch eine Aufteilung der einphasig angeschlossenen Erzeugungseinheiten je
            Außenleiter eingehalten, so dass bei Erzeugungsanlagen/Energiespeichern die Unsymmetrie auf 4,6 kVA je
            Außenleiter begrenzt ist.
        </div>
        <div className="flex justify-center">
            <RadioButton
                id="complianceWithSymmetryCondition-by-splitting"
                value="splitting"
                {...register(`acknowledgements.complianceWithSymmetryCondition`, {
                    required: REQUIRED_ERROR_MESSAGE,
                })}
            />
        </div>
        <div className="col-span-3">
            Die Symmetriebedingung wird durch eine Symmetrieeinrichtung, die den Unsymmetriewert auf 4,6 kVA je
            Außenleiter begrenzt, eingehalten.
        </div>
        <div className="flex justify-center">
            <RadioButton
                id="complianceWithSymmetryCondition-by-symmetryDevice"
                value="symmetryDevice"
                {...register(`acknowledgements.complianceWithSymmetryCondition`, {
                    required: REQUIRED_ERROR_MESSAGE,
                })}
            />
        </div>
    </>
);

import { CompletionConfirmation } from '@/connection-assurance/commissioning/completion-confirmation/CompletionConfirmation';
import { DocumentUpload } from '@/connection-assurance/commissioning/document-upload/DocumentUpload';
import { PlantComponentsGenerator } from '@/connection-assurance/commissioning/plant-components-generator/PlantComponentsGenerator';
import { PlantComponentsStorage } from '@/connection-assurance/commissioning/plant-components-storage/PlantComponentsStorage';
import { SubprocessValue } from '@/connection-assurance/commissioning/wizard/constants';
import { Context } from '@/connection-assurance/commissioning/wizard/context';
import {
    NEXT_EVENT,
    PREV_EVENT,
    SYNC_LOCATION_WITH_STATE,
    SYNC_STATE_WITH_LOCATION,
} from '@/connection-assurance/commissioning/wizard/events';
import { getFurthestSteps } from '@/connection-assurance/commissioning/wizard/getFurthestSteps';
import guards from '@/connection-assurance/commissioning/wizard/guards';
import useWizardMachine, { getLocalStorageEntryName } from '@/connection-assurance/commissioning/wizard/wizard';
import {
    CONNECTION_ASSURANCE_COMMISSIONING,
    CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION,
    CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD,
    CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS,
    CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR,
    CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE,
    DASHBOARD,
} from '@/routes';
import { ConnectionAssurance } from '@/types';
import { formatPlantOperatorName } from '@/utils/formatting';
import { WizardStep } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { Route, Switch, generatePath, useHistory, useLocation } from 'react-router-dom';

import { PlantComponentProps } from './CommissioningProcessProps';
import { NextSteps } from './next-steps/NextSteps';

type Props = {
    connectionAssurance: ConnectionAssurance;
};

export const ConnectionAssuranceCommissioningRouting = ({
    connectionAssurance,
    allPlantComponents,
}: Props & PlantComponentProps) => {
    const history = useHistory();
    const location = useLocation();
    const [state, send] = useWizardMachine(history, location.pathname, connectionAssurance);
    const title = `Inbetriebsetzung Einspeisung – ${formatPlantOperatorName(connectionAssurance.plantData.plantOperator)} (${connectionAssurance.processCommunicationId})`;

    const updateContext = React.useCallback(
        (payload: Partial<Context>, process?: SubprocessValue) => {
            const meta = process ? { ...getFurthestSteps(process), ...payload.meta } : undefined;
            if (meta) {
                return send({
                    type: 'UPDATE_CONTEXT',
                    value: { ...payload, meta },
                });
            }
            return send({
                type: 'UPDATE_CONTEXT',
                value: payload,
            });
        },
        [send],
    );

    const onSubmit = React.useCallback(() => send(NEXT_EVENT), [send]);

    const goBack = () => send(PREV_EVENT);

    React.useEffect(
        () =>
            history.listen(() => {
                window.scrollTo(0, 0);
            }),
        [history],
    );

    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (
                statePath !== undefined &&
                location.pathname !== statePath &&
                location.pathname.startsWith(
                    generatePath(CONNECTION_ASSURANCE_COMMISSIONING, { connectionAssuranceId: connectionAssurance.id }),
                )
            ) {
                send({ type: SYNC_STATE_WITH_LOCATION, path: location.pathname });
            }
        },
        // eslint-disable-next-line
    [location.pathname],
    );

    // refactor to wizard and non wizard component
    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (
                statePath !== undefined &&
                location.pathname !== statePath &&
                location.pathname.startsWith(
                    generatePath(CONNECTION_ASSURANCE_COMMISSIONING, { connectionAssuranceId: connectionAssurance.id }),
                )
            ) {
                send({ type: SYNC_LOCATION_WITH_STATE });
            }
        }, // eslint-disable-next-line
    [state.context.meta.path],
    );

    const goToDashboard = () => {
        localStorage.removeItem(getLocalStorageEntryName(connectionAssurance.id));
        window.history.pushState({}, document.title, DASHBOARD);
    };

    return (
        <div className="flex flex-col gap-x-10 w-full gap-y-5">
            <div className="w-3/4 my-10 pl-30 mx-auto">
                <WizardStep
                    steps={state.context.meta.steps}
                    furthestStep={[
                        state.context.meta.furthestStep.furthestProcess,
                        state.context.meta.furthestStep.furthestSubprocess,
                    ]}
                    hasError={state.value === 'nextSteps'}
                />
            </div>
            <div
                className={`w-full ${state.context.meta.inRequestReview ? '[&_form_:invalid]:border-primary [&_form_:invalid]:border-2' : ''}`}
                data-testid="routing-wrapperDiv"
            >
                <Switch>
                    <Route path={CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR}>
                        <PlantComponentsGenerator
                            onSubmit={onSubmit}
                            goBack={goToDashboard}
                            updateContext={updateContext}
                            context={state.context}
                            title={title}
                            connectionAssurance={connectionAssurance}
                            allPlantComponents={allPlantComponents}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE}>
                        <PlantComponentsStorage
                            onSubmit={onSubmit}
                            goBack={guards.isPlantComponentsGeneratorShown(state.context) ? goBack : goToDashboard}
                            updateContext={updateContext}
                            context={state.context}
                            title={title}
                            connectionAssurance={connectionAssurance}
                            allPlantComponents={allPlantComponents}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD}>
                        <DocumentUpload
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            title={title}
                            connectionAssurance={connectionAssurance}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION}>
                        <CompletionConfirmation
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            title={title}
                            connectionAssurance={connectionAssurance}
                        />
                    </Route>

                    <Route path={CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS}>
                        <NextSteps
                            onSubmit={onSubmit}
                            goBack={goBack}
                            updateContext={updateContext}
                            context={state.context}
                            title={title}
                            connectionAssurance={connectionAssurance}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { FormItem, StyledLink, TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Context } from '../wizard/context';

type MasterDataRegisterNumberFieldProps = {
    showGeneration?: boolean;
    showStorage?: boolean;
};

export const MasterDataRegisterNumberField = ({ showGeneration, showStorage }: MasterDataRegisterNumberFieldProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<Context>();

    if (!showGeneration && !showStorage) {
        return null;
    }

    const registerOptions = {
        required: REQUIRED_ERROR_MESSAGE,
        pattern: {
            value: /^\d{12}$/,
            message: `Bitte geben Sie exakt 12 Zeichen ein.`,
        },
    };

    return (
        <>
            <FormItem
                label="Bitte geben Sie die Marktstammdatenregister-Nummer der Anlage an:"
                className="max-w-[32rem]"
            >
                {showGeneration && (
                    <div className="mb-4">
                        <TextField
                            label="Marktstammdatenregister-Nummer der Erzeugungsanlage"
                            additionalClassName="pl-9"
                            prefix="SEE"
                            id="generatorData.maStRGenerationPlant"
                            data-testid="generatorData.maStRGenerationPlant"
                            displayErrorMessage={errors?.generatorData?.maStRGenerationPlant?.message}
                            {...register('generatorData.maStRGenerationPlant', registerOptions)}
                        />
                    </div>
                )}
                {showStorage && (
                    <div className="mb-4">
                        <TextField
                            label="Marktstammdatenregister-Nummer des Energiespeichersystems"
                            additionalClassName="pl-9"
                            prefix="SEE"
                            id="storageData.maStRGeneratorStorage"
                            data-testid="storageData.maStRGeneratorStorage"
                            displayErrorMessage={errors?.storageData?.maStRGeneratorStorage?.message}
                            {...register('storageData.maStRGeneratorStorage', registerOptions)}
                        />
                    </div>
                )}
            </FormItem>

            <p className="text-small col-span-2">
                <b>Hinweis:</b> Bitte melden Sie die Inbetriebsetzung im{' '}
                <StyledLink target="_blank" href="https://www.marktstammdatenregister.de/MaStR">
                    Marktstammdatenregister
                </StyledLink>{' '}
                an. Die Registrierung der Anlage ist spätestens einen Monat nach der Inbetriebsetzung abzuschließen.
            </p>
        </>
    );
};

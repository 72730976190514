import { IncreasedCompensation } from '@/connection-assurance/compensation/types/CompensationData';
import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import {
    Form,
    FormItem,
    FormLayout,
    FormSubTitle,
    RadioButton,
    StyledLink,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { useContextUpdate } from '../wizard/hooks';

export const IncreasedCompensationForm = ({
    goBack,
    onSubmit,
    updateContext,
    context,
    title,
}: RouteProperties & CompensationProcessProps) => {
    const { handleSubmit, register, watch, control } = useForm<IncreasedCompensation>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            increasedCompensation: context.increasedCompensation || 'yes',
        },
    });

    useContextUpdate(watch, updateContext, 'COMPENSATION_INCREASED_COMPENSATION');

    const increasedCompensation = useWatch({ control, name: 'increasedCompensation' });

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: true,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        nextLabel: 'Weiter',
        prevLabel: 'Zurück',
    });

    return (
        <Form title={title} rightButton={nextButton} leftButton={prevButton} handleSubmit={handleSubmit(onSubmit)}>
            <FormSubTitle>Erhöhte Vergütung</FormSubTitle>
            <FormLayout>
                <FormItem
                    label="Soll die erhöhte Vergütung in Anspruch genommen werden?"
                    className="flex mb-8 justify-start w-full gap-x-24"
                >
                    <RadioButton
                        id="increasedCompensation-yes"
                        name="increasedCompensation"
                        value="yes"
                        data-testid="increasedCompensation-yes"
                        {...register('increasedCompensation', {
                            required: REQUIRED_ERROR_MESSAGE,
                        })}
                    >
                        ja
                    </RadioButton>
                    <RadioButton
                        id="increasedCompensation-no"
                        name="increasedCompensation"
                        value="no"
                        data-testid="increasedCompensation-no"
                        {...register('increasedCompensation', {
                            required: REQUIRED_ERROR_MESSAGE,
                        })}
                    >
                        nein
                    </RadioButton>
                </FormItem>
            </FormLayout>
            {increasedCompensation === 'yes' && (
                <div className="shadow-ten p-8 w-fit" data-testid="further-information-card">
                    <p className="mb-4">
                        Sie bestätigen hiermit, dass folgende Voraussetzungen für die erhöhte Vergütung erfüllt sind:
                    </p>
                    <ul className="teagTickList flex flex-col gap-y-2 ms-8">
                        <li>Der Strom wird vollständig an den Netzbetreiber geliefert.</li>
                        <li>
                            Bei mehreren Erzeugungsanlagen an einem Anschlusspunkt verfügen die Anlagen über separate
                            Messungen.
                        </li>
                        <li>
                            Ist ein Verbraucher hinter dem Netzverknüpfungspunkt angeschlossen, wird über eine
                            Erzeugungsmessung nachgewiesen, dass der erzeugte Strom ausschließlich ins Netz eingespeist
                            wird.
                        </li>
                        <li>
                            Weitere Informationen zur erhöhten Vergütung finden Sie{' '}
                            <StyledLink
                                href="https://www.bundesnetzagentur.de/DE/Fachthemen/ElektrizitaetundGas/ErneuerbareEnergien/EEG_Foerderung/start.html"
                                target="_blank"
                            >
                                hier
                            </StyledLink>
                            .
                        </li>
                    </ul>
                </div>
            )}
        </Form>
    );
};

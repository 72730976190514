import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import { Form, FormSubTitle, createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { CompensationProcessProps } from '../CompensationProcessProps';

export const NextSteps = ({ goBack, onSubmit, title }: RouteProperties & CompensationProcessProps) => {
    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: true,
        onBack: goBack,
        onNext: () => onSubmit({}),
        nextLabel: 'Weiter',
    });

    return (
        <Form title={title} leftButton={prevButton} rightButton={nextButton}>
            <FormSubTitle>Nächste Schritte</FormSubTitle>
        </Form>
    );
};

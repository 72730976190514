import { AccountInfo } from '@azure/msal-browser';
import { CustomerType, TenIdTokenClaims } from '@ten-netzkundenportal/ui-auth-utils';

export function getCustomerType(accounts: AccountInfo[]) {
    const customerType =
        accounts.length > 0
            ? ((accounts[0].idTokenClaims as TenIdTokenClaims).extension_customerType as CustomerType)
            : undefined;

    return customerType;
}

import {
    CommissioningData,
    CommissioningDataAcknowledgements,
} from '@/connection-assurance/commissioning/types/CommissioningData';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { Checkbox } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { UseFormRegister, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';

type AcknowledgementCheckboxProps = {
    title?: string;
    label: React.ReactNode;
    id: FieldPath<CommissioningDataAcknowledgements>;
    register: UseFormRegister<CommissioningData>;
    trigger: UseFormTrigger<CommissioningData>;
};

export const AcknowledgementCheckbox = ({ title, label, id, register, trigger }: AcknowledgementCheckboxProps) => (
    <>
        {title && <p className="col-span-4 font-bold mt-6">{title}</p>}

        <div className="col-span-3">{label}</div>
        <div className="flex justify-center">
            <Checkbox
                id={`${id}-checkbox`}
                {...register(`acknowledgements.${id}`, {
                    required: REQUIRED_ERROR_MESSAGE,
                    onChange: () => trigger(`acknowledgements.${id}`),
                })}
            />
        </div>
    </>
);

import {
    ConnectionAssuranceAddressLocation,
    ConnectionAssuranceCadastralDistrictLocation,
    StateCode,
} from '@/types/ConnectionAssuranceLocation';
import { Person } from '@/types/Person';
import { CompanyName, PlantOperator, PrivatePlantOperator } from '@/types/PlantData';

export const isAddressConnectionAssurance = (
    location: ConnectionAssuranceAddressLocation | ConnectionAssuranceCadastralDistrictLocation,
): location is ConnectionAssuranceAddressLocation => 'cityName' in location;

export const isSaxony = (
    cadastralDistrictLocationOrStateCode: ConnectionAssuranceCadastralDistrictLocation | StateCode,
) => {
    if (!cadastralDistrictLocationOrStateCode) {
        return false;
    }

    if (typeof cadastralDistrictLocationOrStateCode === 'string') {
        return cadastralDistrictLocationOrStateCode === 'SN' || cadastralDistrictLocationOrStateCode === '14';
    }

    if (cadastralDistrictLocationOrStateCode.stateCode) {
        return (
            cadastralDistrictLocationOrStateCode.stateCode === 'SN' ||
            cadastralDistrictLocationOrStateCode.stateCode === '14'
        );
    }
    return false;
};

export const isPrivatePlantOperator = (plantOperator: PlantOperator): plantOperator is PrivatePlantOperator =>
    'person' in plantOperator;

export const isCustomerCompany = (customer: Person | CompanyName): customer is CompanyName => 'companyName' in customer;

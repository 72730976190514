import { FormLayoutForBiggerChildren } from '@/components/FormLayoutForBiggerChildren';
import { GeneratorLocationData, GeneratorLocationType } from '@/connection-assurance/compensation/types/LocationData';
import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import {
    Dropdown,
    Form,
    FormItem,
    FormSubTitle,
    IconButton,
    TeagEnergieloesungen,
    TeagPiktoBaulicheanlage250107Cyan,
    TeagPiktoFreiflaeche250107Cyan,
    TeagPiktoLaermschutzwand250107Cyan,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm } from 'react-hook-form';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { useContextUpdate } from '../wizard/hooks';

export const LocationData = ({
    goBack,
    onSubmit,
    context,
    updateContext,
    title,
}: RouteProperties & CompensationProcessProps) => {
    const [generatorLocations, setGeneratorLocations] = React.useState(new Set(context.generatorLocations ?? []));

    const {
        watch,
        register,
        setValue,
        formState: { isValid },
        handleSubmit,
    } = useForm<GeneratorLocationData>({
        mode: 'onTouched',
        defaultValues: {
            buildingType: context.buildingType,
        },
    });

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: isValid && generatorLocations.size > 0,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        nextLabel: 'Weiter',
    });

    const toggleGeneratorLocations = (
        oldGeneratorLocations: Set<GeneratorLocationType>,
        generatorLocationType: GeneratorLocationType,
    ) => {
        const newGeneratorLocations = new Set(oldGeneratorLocations);

        if (!newGeneratorLocations.has(generatorLocationType)) {
            newGeneratorLocations.add(generatorLocationType);
            return newGeneratorLocations;
        }

        newGeneratorLocations.delete(generatorLocationType);

        if (generatorLocationType === 'building') {
            setValue('buildingType', undefined);
            updateContext({ buildingType: undefined }, 'PLANT_LOCATION_DATA');
        }

        return newGeneratorLocations;
    };

    useContextUpdate(watch, updateContext, 'PLANT_LOCATION_DATA');

    React.useEffect(() => {
        const generatorLocationsArray = Array.from(generatorLocations);
        updateContext(
            {
                generatorLocations: generatorLocationsArray,
            },
            'PLANT_LOCATION_DATA',
        );
    }, [generatorLocations, updateContext]);

    return (
        <Form title={title} leftButton={prevButton} rightButton={nextButton}>
            <FormSubTitle>Standort der Erzeugungsanlage</FormSubTitle>
            <FormLayoutForBiggerChildren>
                <FormItem
                    label="Wo befindet sich die Erzeugungsanlage?"
                    className="items-center gap-x-4 grid grid-cols-[1fr_1fr_1fr_1fr]"
                >
                    <div className="w-full h-28">
                        <IconButton
                            name="building"
                            title="building"
                            isInitiallyChecked={generatorLocations.has('building')}
                            iconSize="h-14"
                            labelVerticalOne="Gebäude"
                            icon={<TeagEnergieloesungen className="w-10 mt-1" />}
                            onClick={() => {
                                setGeneratorLocations((oldGeneratorLocations) =>
                                    toggleGeneratorLocations(oldGeneratorLocations, 'building'),
                                );
                            }}
                            padding="1"
                        />
                    </div>
                    <div className="w-full h-28">
                        <IconButton
                            name="noiseBarrierWall"
                            title="noiseBarrierWall"
                            isInitiallyChecked={generatorLocations.has('noiseBarrierWall')}
                            iconSize="h-14"
                            labelVerticalOne="Lärmschutzwand"
                            icon={<TeagPiktoLaermschutzwand250107Cyan className="w-14" />}
                            onClick={() => {
                                setGeneratorLocations((oldGeneratorLocations) =>
                                    toggleGeneratorLocations(oldGeneratorLocations, 'noiseBarrierWall'),
                                );
                            }}
                            padding="1"
                        />
                    </div>
                    <div className="w-full h-28">
                        <IconButton
                            name="openSpace"
                            title="openSpace"
                            isInitiallyChecked={generatorLocations.has('openSpace')}
                            iconSize="h-14"
                            labelVerticalOne="Freifläche"
                            icon={<TeagPiktoFreiflaeche250107Cyan className="w-14" />}
                            onClick={() => {
                                setGeneratorLocations((oldGeneratorLocations) =>
                                    toggleGeneratorLocations(oldGeneratorLocations, 'openSpace'),
                                );
                            }}
                            padding="1"
                        />
                    </div>
                    <div className="w-full h-28">
                        <IconButton
                            name="constructionOrOther"
                            title="constructionOrOther"
                            isInitiallyChecked={generatorLocations.has('constructionOrOther')}
                            iconSize="h-14"
                            labelVerticalOne="Bauliche Anlage / Sonstiges"
                            icon={<TeagPiktoBaulicheanlage250107Cyan className="w-14" />}
                            onClick={() => {
                                setGeneratorLocations((oldGeneratorLocations) =>
                                    toggleGeneratorLocations(oldGeneratorLocations, 'constructionOrOther'),
                                );
                            }}
                            padding="1"
                        />
                    </div>
                </FormItem>
                {generatorLocations.has('building') && (
                    <FormItem label="Um welche Art des Gebäudes handelt es sich?" className="items-center gap-x-4">
                        <Dropdown
                            options={[
                                {
                                    label: 'Wohngebäude',
                                    value: 'residential',
                                },
                                {
                                    label: 'Sonstiges Gebäude (z.B. Carport, Scheune, Lagerhalle)',
                                    value: 'other',
                                },
                            ]}
                            {...register('buildingType', {
                                required: generatorLocations.has('building'),
                                shouldUnregister: true,
                            })}
                            title="dropdown-building"
                        />
                    </FormItem>
                )}
            </FormLayoutForBiggerChildren>
        </Form>
    );
};

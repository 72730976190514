import { CompensationDocumentsFileCategory } from '@/types/FileUpload';
import PrintOutLink from '@components/documentUpload/documentUploadForm/PrintOutLink';
import { InfoI, StyledLink } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

export default (
    fileCategory: CompensationDocumentsFileCategory,
    boundaryDefiningParentRef: MutableRefObject<HTMLElement>,
) => {
    switch (fileCategory) {
        case 'bindingDeclaration':
            return (
                <>
                    <br />
                    (hier finden Sie eine Übersicht der{' '}
                    <StyledLink
                        href="https://www.thueringer-energienetze.com/Einspeisung/Stromnetz/Erzeugungsanlagen_bis_30kW"
                        target="_blank"
                    >
                        Vordrucke
                    </StyledLink>
                    )
                </>
            );
        case 'declarationOfReceiptOfVAT':
            return (
                <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/TEN-AR4-5_Erklaerung_ueber_Berechtigung_Erhalt_UST_SEPA.pdf" />
            );

        case 'registrationConfirmationGenerationPlant':
        case 'registrationConfirmationEnergyStorage':
            return (
                <span className="checklist-info-i more-margin">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Die Registrierungsbestätigung können Sie im Marktstammdatenregister herunterladen. Diese
                                muss den Betriebsstatus “in Betrieb” haben.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );

        default:
            return undefined;
    }
};

import { LandlordToTenantsElectricityBonus } from '@/connection-assurance/compensation/types/CompensationData';
import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import {
    Form,
    FormItem,
    FormLayout,
    FormSubTitle,
    InfoI,
    RadioButton,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm } from 'react-hook-form';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { useContextUpdate } from '../wizard/hooks';

export const LandlordToTenantsElectricityBonusForm = ({
    goBack,
    onSubmit,
    updateContext,
    context,
    title,
}: RouteProperties & CompensationProcessProps) => {
    const { handleSubmit, register, watch } = useForm<LandlordToTenantsElectricityBonus>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            landlordToTenantsElectricityBonus: context.landlordToTenantsElectricityBonus ?? 'no',
        },
    });

    useContextUpdate(watch, updateContext, 'LANDLORD_TO_TENANTS_ELECTRICITY_BONUS');

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: true,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        nextLabel: 'Weiter',
    });

    return (
        <Form title={title} leftButton={prevButton} rightButton={nextButton}>
            <FormSubTitle>Mieterstromzuschlag</FormSubTitle>
            <FormLayout>
                <FormItem
                    label={
                        <>
                            Soll der Mieterstromzuschlag in Anspruch genommen werden?
                            <div
                                className="inline-block ml-2 w-3 overflow-visible fill-current text-primary
                        hover:text-dark-blue"
                            >
                                <InfoI
                                    text={
                                        <span className="hyphens-auto">
                                            Als Mieterstrom wird Strom bezeichnet, der auf einem (Wohn-)Gebäude erzeugt
                                            und direkt, ohne Durchleitung durch ein Netz (§ 21 Abs. 3 Satz 1 EEG 2023),
                                            an Letztverbraucher in Wohngebäuden geliefert wird.
                                        </span>
                                    }
                                />
                            </div>
                        </>
                    }
                    className="flex mb-8 justify-start w-full gap-x-24"
                >
                    <RadioButton
                        id="tenantsElectricityBonus-yes"
                        name="tenantsElectricityBonus"
                        value="yes"
                        {...register('landlordToTenantsElectricityBonus', {
                            required: REQUIRED_ERROR_MESSAGE,
                        })}
                    >
                        ja
                    </RadioButton>
                    <RadioButton
                        id="tenantsElectricityBonus-no"
                        name="tenantsElectricityBonus"
                        value="no"
                        {...register('landlordToTenantsElectricityBonus', {
                            required: REQUIRED_ERROR_MESSAGE,
                        })}
                    >
                        nein
                    </RadioButton>
                </FormItem>
            </FormLayout>
        </Form>
    );
};

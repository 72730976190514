import { PersistedDocument, PersistedDocumentCategory } from '@/types/Document';
import { Dropdown, ErrorBox, FormSubTitle } from '@ten-netzkundenportal/ui-components';
import React, { useEffect, useState } from 'react';

import ConnectionAssuranceDocumentViewLink from './ConnectionAssuranceDocumentViewLink';

interface DocumentListAreaProps {
    documentList: PersistedDocument[];
    documentsLoadingError: boolean;
    loadDocument: (documentId: string) => Promise<{ blob: Blob; type: string }>;
    documentUploadPossible: boolean;
}

export const fileCategoryMapper: Record<PersistedDocumentCategory, string> = {
    compensationDocuments: 'Vergütungsrelevante Unterlagen',
    generatingPlantCommissioning: 'Notwendige Unterlagen zur Inbetriebsetzung der Erzeugungsanlage',
    misc: 'Sonstige Dokumente',
    networkCompatibility: 'Unterlagen zur Netzverträglichkeitsprüfung',
    networkSecurityManagement: 'Netzsicherheitsmanagement',
    outbox: 'Posteingang',
    projectDocuments: 'Anschlussrelevante Projektunterlagen',
    transformerStationCommissioning:
        'Notwendige Unterlagen vor Inbetriebnahme der Übergabe- / Transformatorenstation (Anschlussnehmerstation) oder des Umspannwerkes',
    controllableConsumption: 'Steuerbare Verbrauchseinrichtung',
};

const DocumentListArea = ({
    documentList,
    documentsLoadingError,
    loadDocument,
    documentUploadPossible,
}: DocumentListAreaProps): React.ReactElement => {
    const [filteredDocumentList, setFilteredDocumentList] = useState<PersistedDocument[]>([]);
    const [showDownloadError, setShowDownloadError] = useState<boolean>(false);
    const [hideDocumentsLoadingError, setHideDocumentsLoadingError] = useState<boolean>(false);

    const availableDocumentCategory = new Set(documentList.map((value) => value.documentCategory));

    const options = [
        { value: 'all', label: 'Alle' },
        ...Array.from(availableDocumentCategory).map((category) => ({
            value: category,
            label: fileCategoryMapper[category],
        })),
    ];

    const handleDocumentCategoryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const documentCategory = e.target.value;
        if (documentList?.length === 0) return;
        if (documentCategory === 'all') {
            setFilteredDocumentList(documentList);
            return;
        }
        const filtered = documentList.filter((document) => document.documentCategory === documentCategory);
        setFilteredDocumentList(filtered);
    };

    // will be rendered only once (documentList shouldn't change)
    useEffect(() => {
        setFilteredDocumentList(documentList);
    }, [documentList]);

    const createDocumentList = () =>
        filteredDocumentList.map((filteredDocument) => (
            <div className="flex w-full" key={filteredDocument.documentId}>
                <div className="grid grid-cols-2 border-b border-dark-grey place-items-center gap-x-8 h-16 break-all w-full">
                    <ConnectionAssuranceDocumentViewLink
                        {...filteredDocument}
                        loadDocument={loadDocument}
                        setShowDownloadError={setShowDownloadError}
                    />
                    <p title={fileCategoryMapper[filteredDocument.documentCategory]} className="py-2 truncate w-full">
                        {fileCategoryMapper[filteredDocument.documentCategory]}
                    </p>
                </div>
            </div>
        ));

    const createErrorMessage = () =>
        documentsLoadingError ? (
            !hideDocumentsLoadingError && (
                <ErrorBox position="!place-self-start" onClick={() => setHideDocumentsLoadingError(true)} />
            )
        ) : (
            <span>
                Zum diesem Vorgang liegen derzeit keine Dokumente vor.
                {documentUploadPossible && (
                    <span> Sie können Dokumente im oberen Bereich &bdquo;Dokumentenupload&ldquo; hochladen.</span>
                )}
            </span>
        );

    return (
        <div className="flex flex-col gap-y-6">
            <FormSubTitle>Dokumentensuche</FormSubTitle>
            <span>
                In diesem Bereich finden Sie alle vorliegenden Dokumente zu diesem Vorgang. Sie können diese ansehen,
                indem Sie auf den Dokumentennamen klicken.
                {documentUploadPossible &&
                    ' Sie haben gerade neue Dokumente hochgeladen? Es kann einen kurzen Moment dauern, bis diese angezeigt werden.'}
            </span>
            <div className="flex flex-col gap-y-2 px-4 bg-blue-50 py-4">
                <span className="font-bold">Kategorie</span>

                <Dropdown
                    defaultValue="all"
                    onChange={handleDocumentCategoryChange}
                    dropdownClassname="w-[36rem]"
                    options={options}
                    data-testid="documentCategory"
                />
            </div>

            {!documentsLoadingError && documentList?.length > 0 ? (
                <>
                    <div className="max-h-[36rem] overflow-auto custom-scrollbar">
                        <div className="flex w-full px-4">
                            <div className="grid grid-cols-2 h-12 gap-x-8 border-b border-dark-grey w-full">
                                <span className="font-bold flex pb-2">Name</span>
                                <span className="font-bold flex pb-2">Kategorie</span>
                            </div>
                        </div>
                        <div className="flex flex-col place-items-start h-full px-4" data-testid="documentEntry">
                            {filteredDocumentList?.length && createDocumentList()}
                        </div>
                    </div>

                    {showDownloadError && (
                        <ErrorBox
                            position="bottom-5"
                            onClick={() => setShowDownloadError(false)}
                            text={[
                                'Beim Laden des Dokuments ist leider ein Fehler aufgetreten.',
                                'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                            ]}
                        />
                    )}
                </>
            ) : (
                createErrorMessage()
            )}
        </div>
    );
};
export default DocumentListArea;

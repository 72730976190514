import { Address } from '@/types/PlantData';
import { flattenErrors } from '@/utils/flattenErrors';
import { registerWrapper } from '@/utils/formHookHelper';
import { REQUIRED_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE } from '@/utils/textConstants';
import { TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type FormProps = {
    registerPrefix: string;
    disabled?: boolean;
    withAdditionalAddressInformation?: boolean;
};

type PrefixedAddress<Prefix extends string> = {
    [Key in keyof Address as `${Prefix}.${Key & string}`]: Address[Key];
};

const AddressFields = ({
    registerPrefix,
    disabled = false,
    withAdditionalAddressInformation = false,
}: FormProps): React.ReactElement => {
    const {
        register,
        formState: { errors },
    } = useFormContext<PrefixedAddress<typeof registerPrefix>>();

    const flattenedErrors = flattenErrors<Address>(registerPrefix, errors);

    return (
        <div>
            <div className="grid grid-cols-3 space-x-2 h-20 w-full">
                <div className="col-span-2">
                    <TextField
                        name="street"
                        label="Straße"
                        displayErrorMessage={flattenedErrors?.street?.message}
                        disabled={disabled}
                        {...registerWrapper(register, `${registerPrefix}.street`, {
                            required: REQUIRED_ERROR_MESSAGE,
                            minLength: { value: 2, message: VALIDATION_ERROR_MESSAGE },
                            maxLength: { value: 50, message: VALIDATION_ERROR_MESSAGE },
                        })}
                    />
                </div>
                <div className="col-span-1">
                    <TextField
                        name="houseNumber"
                        label="Hausnummer"
                        displayErrorMessage={flattenedErrors?.houseNumber?.message}
                        size={1}
                        disabled={disabled}
                        {...registerWrapper(register, `${registerPrefix}.houseNumber`, {
                            required: REQUIRED_ERROR_MESSAGE,
                            minLength: { value: 1, message: VALIDATION_ERROR_MESSAGE },
                            maxLength: { value: 10, message: VALIDATION_ERROR_MESSAGE },
                            pattern: { value: /^[1-9]\d*/, message: VALIDATION_ERROR_MESSAGE },
                        })}
                    />
                </div>
            </div>
            {withAdditionalAddressInformation && (
                <div className="h-20">
                    <TextField
                        name="additionalAddressInformation"
                        label={
                            <span className="text-small">
                                Adresszusatz <span className="text-darkest-grey">(optional)</span>
                            </span>
                        }
                        displayErrorMessage={flattenedErrors?.additionalAddressInformation?.message}
                        disabled={disabled}
                        {...registerWrapper(register, `${registerPrefix}.additionalAddressInformation`, {
                            required: false,
                        })}
                    />
                </div>
            )}
            <div className="grid grid-cols-3 space-x-2 h-20">
                <div className="col-span-1">
                    <TextField
                        name="postalCode"
                        label="Postleitzahl"
                        displayErrorMessage={flattenedErrors?.postalCode?.message}
                        size={1}
                        disabled={disabled}
                        {...registerWrapper(register, `${registerPrefix}.postalCode`, {
                            required: REQUIRED_ERROR_MESSAGE,
                            minLength: { value: 5, message: VALIDATION_ERROR_MESSAGE },
                            maxLength: { value: 5, message: VALIDATION_ERROR_MESSAGE },
                            pattern: { value: /^\d+$/, message: VALIDATION_ERROR_MESSAGE },
                        })}
                    />
                </div>
                <div className="col-span-2">
                    <TextField
                        name="city"
                        label="Ort"
                        displayErrorMessage={flattenedErrors?.city?.message}
                        disabled={disabled}
                        {...registerWrapper(register, `${registerPrefix}.city`, {
                            required: REQUIRED_ERROR_MESSAGE,
                            minLength: { value: 2, message: VALIDATION_ERROR_MESSAGE },
                            maxLength: { value: 30, message: VALIDATION_ERROR_MESSAGE },
                        })}
                    />
                </div>
            </div>
            <div className="h-20 ">
                <span className="text-small mb-1">Land</span>
                <div
                    className="flex items-center border border-dark-grey min-w-full
                                px-2 py-1 h-10 bg-light-grey hover:cursor-not-allowed bg-opacity-25"
                >
                    <span className="text-darkest-grey">Deutschland</span>
                </div>
            </div>
        </div>
    );
};

export default AddressFields;

import { CommissioningGenerationPlantDocumentsFileCategory } from '@/types/FileUpload';
import { PlantComponent } from '@/types/PlantComponent';
import { PlantSize } from '@/types/PlantSize';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: CommissioningGenerationPlantDocumentsFileCategory;
};

export function getCommissioningGenerationPlantDocumentsFileCategories(
    plantSize: PlantSize,
    plantComponents: PlantComponent[],
): UploadFileCategory[] {
    const totalActivePower = calculateSumOfActivePowersInKW(plantComponents);

    const options: Option[] = [
        {
            label: 'Anzeige zur Inbetriebsetzung',
            value: 'commissioningDisplay',
        },
        plantSize === 'medium' || plantSize === 'big'
            ? {
                  label: 'Errichtererklärung und Betriebsbereitschaftserklärung',
                  value: 'builderAndOperationReadinessDeclaration',
              }
            : undefined,
        totalActivePower >= 135
            ? {
                  label: 'Schutzprüfprotokoll',
                  value: 'protectionTestProtocol',
              }
            : undefined,
        {
            label: 'Inbetriebsetzungsprotokoll',
            value: 'commissioningProtocol',
        },
        plantSize === 'small'
            ? {
                  label: 'Fotodokumentation Erzeugungsanlage',
                  value: 'generationPlantPhotoDocumentation',
              }
            : undefined,
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef, plantSize),
            tags: { category: 'commissioningGenerationPlantDocuments', subCategory: option.value },
        }));
}

import { PlantComponentGenerator } from '@/types/PlantComponent';

export const transformPlantComponentsGenerator = (
    plantComponents: PlantComponentGenerator[],
    plantComponentUsedForDcStorageId: string | undefined,
): PlantComponentGenerator[] => {
    if (plantComponents.length > 1 && !plantComponentUsedForDcStorageId) {
        return plantComponents;
    }

    return plantComponents.map((plantComponent) => ({
        ...plantComponent,
        usedForDcStorage:
            plantComponents.length === 1
                ? true
                : plantComponent.plantComponentId?.toString() === plantComponentUsedForDcStorageId,
    }));
};

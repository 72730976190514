import {
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX,
    CONNECTION_ASSURANCE_COMPENSATION_COMPLETION,
    CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD,
    CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
    CONNECTION_ASSURANCE_COMPENSATION_NEXT_STEPS,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA,
} from '@/routes';

export const STEPS = {
    PLANT: 'PLANT',
    COMPENSATION: 'COMPENSATION',
    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
    COMPLETION: 'COMPLETION',
    NEXT_STEPS: 'NEXT_STEPS',
} as const;

export type StepsValue = (typeof STEPS)[keyof typeof STEPS];

export const ALL_STEPS: StepsValue[] = [
    STEPS.PLANT,
    STEPS.COMPENSATION,
    STEPS.DOCUMENT_UPLOAD,
    STEPS.COMPLETION,
    STEPS.NEXT_STEPS,
];

export type SubprocessMetaData = {
    processSubprocessIndex?: [number, number];
    path: string;
};

export type SubprocessValue =
    | 'PLANT_COMPENSATION_AGREEMENT'
    | 'PLANT_LOCATION_DATA'
    | 'PLANT_GENERATION_PLANT_DATA'
    | 'PLANT_STORAGE_PLANT_DATA'
    | 'PLANT_EXISTING_GENERATION_PLANT'
    | 'PLANT_EXISTING_SOLAR_PLANT'
    | 'LANDLORD_TO_TENANTS_ELECTRICITY_BONUS'
    | 'COMPENSATION_INCREASED_COMPENSATION'
    | 'COMPENSATION_BANK_ACCOUNT_DETAILS'
    | 'COMPENSATION_SALES_TAX'
    | 'DOCUMENT_UPLOAD'
    | 'COMPLETION'
    | 'NEXT_STEPS';

type SubprocessType = Record<SubprocessValue, SubprocessMetaData>;

export const SUBPROCESS: SubprocessType = {
    PLANT_COMPENSATION_AGREEMENT: {
        processSubprocessIndex: [0, 0],
        path: CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION,
    },
    PLANT_LOCATION_DATA: {
        processSubprocessIndex: [0, 1],
        path: CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA,
    },
    PLANT_GENERATION_PLANT_DATA: {
        processSubprocessIndex: [0, 2],
        path: CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA,
    },
    PLANT_STORAGE_PLANT_DATA: {
        processSubprocessIndex: [0, 3],
        path: CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA,
    },
    PLANT_EXISTING_GENERATION_PLANT: {
        processSubprocessIndex: [0, 4],
        path: CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT,
    },
    PLANT_EXISTING_SOLAR_PLANT: {
        processSubprocessIndex: [0, 5],
        path: CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT,
    },

    LANDLORD_TO_TENANTS_ELECTRICITY_BONUS: {
        processSubprocessIndex: [1, 0],
        path: CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
    },
    COMPENSATION_INCREASED_COMPENSATION: {
        processSubprocessIndex: [1, 1],
        path: CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION,
    },
    COMPENSATION_BANK_ACCOUNT_DETAILS: {
        processSubprocessIndex: [1, 2],
        path: CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS,
    },
    COMPENSATION_SALES_TAX: {
        processSubprocessIndex: [1, 3],
        path: CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX,
    },

    DOCUMENT_UPLOAD: {
        processSubprocessIndex: [2, 0],
        path: CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD,
    },

    COMPLETION: {
        processSubprocessIndex: [3, 0],
        path: CONNECTION_ASSURANCE_COMPENSATION_COMPLETION,
    },

    NEXT_STEPS: {
        processSubprocessIndex: [4, 0],
        path: CONNECTION_ASSURANCE_COMPENSATION_NEXT_STEPS,
    },
} as const;

import { UpdateContextFunction } from '@/connection-assurance/commissioning/types/UpdateContextFunction';
import React from 'react';
import { UseFormWatch } from 'react-hook-form';

import { SubprocessValue } from './constants';

export type UseContextUpdateOptions = {
    contextPrefix?: string;
    disabled?: boolean;
};

export const useContextUpdate = (
    watch: UseFormWatch<any>,
    updateContext: UpdateContextFunction,
    process: SubprocessValue,
    opts: UseContextUpdateOptions = {},
): void => {
    const { contextPrefix = '', disabled = false } = opts;

    React.useEffect(() => {
        if (disabled) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }

        const subscription = watch((values, { type }) => {
            const filteredValues = Object.fromEntries(
                Object.entries(values).filter(([, value]) => value !== undefined),
            );

            const processParam = type === 'change' ? process : undefined;

            if (contextPrefix) {
                updateContext({ [contextPrefix]: { ...filteredValues } }, processParam);
            } else {
                updateContext({ ...filteredValues }, processParam);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, updateContext, contextPrefix, disabled, process]);
};

import {
    GenerationPlantData,
    GenerationPlantDataType,
} from '@/connection-assurance/compensation/types/GenerationPlantData';
import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import { flattenErrors } from '@/utils/flattenErrors';
import { formatDate } from '@/utils/formatting';
import { parseDate, validateDateInput } from '@/utils/validateDateInput';
import {
    Form,
    FormItem,
    FormLayout,
    FormSubTitle,
    InfoI,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm } from 'react-hook-form';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { useContextUpdate } from '../wizard/hooks';
import { CompensationDateFormItem } from './CompensationDateFormItem';

export const GenerationPlantDataForm = ({
    goBack,
    onSubmit,
    context,
    updateContext,
    connectionAssurance,
    title,
}: RouteProperties & CompensationProcessProps) => {
    const {
        watch,
        register,
        formState: { isValid, errors },
        handleSubmit,
    } = useForm<GenerationPlantData>({
        mode: 'onTouched',
        defaultValues: {
            generationPlantData: context.generationPlantData,
        },
    });

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: isValid,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        nextLabel: 'Weiter',
    });

    const eegStartUpDate = watch('generationPlantData.eegStartUpDate');
    const flattendErrors = flattenErrors<GenerationPlantDataType>('generationPlantData', errors);

    useContextUpdate(watch, updateContext, 'PLANT_GENERATION_PLANT_DATA');

    return (
        <Form title={title} leftButton={prevButton} rightButton={nextButton}>
            <FormSubTitle>Technische Daten der Erzeugungsanlage</FormSubTitle>
            <FormLayout>
                <FormItem
                    label={
                        <>
                            Ab wann war die Erzeugungsanlage generell betriebsbereit?
                            <div
                                className="inline-block ml-2 w-3 overflow-visible fill-current text-primary
                        hover:text-dark-blue"
                            >
                                <InfoI
                                    text={
                                        <span className="hyphens-auto">
                                            Das EEG-Inbetriebnahmedatum ist das Datum, zu dem die Solarmodule und
                                            Wechselrichter fest verbaut sind und Strom erzeugt wird.
                                        </span>
                                    }
                                />
                            </div>
                        </>
                    }
                >
                    <CompensationDateFormItem
                        dateLabel="EEG-Inbetriebnahmedatum"
                        id="generationPlantData.eegStartUpDate"
                        register={register}
                        displayErrorMessage={flattendErrors?.eegStartUpDate?.message}
                        validateDateInput={(input: string) =>
                            validateDateInput(input, {
                                maxDate: new Date(),
                                maxDateErrorMessage: `Bitte geben Sie ein gültiges Datum ein. Das Datum kann nicht in der Zukunft liegen.`,
                            })
                        }
                        hint={
                            <span className="text-small">
                                <b>Hinweis:</b> Die technische Inbetriebsetzung ist am{' '}
                                {formatDate(
                                    connectionAssurance.commissioningData.technicalCommissioningDateOfGenerator,
                                )}{' '}
                                durch den Installationsbetrieb erfolgt.
                            </span>
                        }
                    />
                </FormItem>

                <FormItem label="Ab wann wurde Strom eingespeist?">
                    <CompensationDateFormItem
                        dateLabel="Stromeinspeisung ab"
                        id="generationPlantData.powerFeedInStartDate"
                        register={register}
                        displayErrorMessage={flattendErrors?.powerFeedInStartDate?.message}
                        validateDateInput={(input: string) =>
                            validateDateInput(input, {
                                minDate: new Date(parseDate(eegStartUpDate)),
                                minDateErrorMessage: `Bitte geben Sie ein gültiges Datum ein. Das Datum kann nicht vor dem ${eegStartUpDate} liegen.`,
                            })
                        }
                    />
                </FormItem>
            </FormLayout>
        </Form>
    );
};

import { DASHBOARD } from '@/routes';
import useConnectionAssurance from '@/utils/useConnectionAssurance';
import { Button, LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ConnectionAssuranceCompensationRouting } from './ConnectionAssuranceCompensationRouting';

export const ConnectionAssuranceCompensationRoot = () => {
    const { connectionAssuranceId } = useParams<{ connectionAssuranceId: string }>();
    const { isLoading, connectionAssurance } = useConnectionAssurance(connectionAssuranceId);

    if (isLoading) {
        return (
            <div className="h-full grid md:grid-rows-5">
                <div className="w-20 h-20 row-start-3 justify-self-center">
                    <LoadingSpinner />
                </div>
            </div>
        );
    }

    if (!connectionAssurance) {
        return (
            <div className="w-full form flex flex-col gap-y-6 sm:gap-y-12 py-8 sm:py-10 relative">
                <div className="flex flex-col gap-y-4">
                    Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </div>

                <div className="grid grid-flow-col mt-10 min-h-min">
                    <div className="button place-self-end flex">
                        <Button
                            label="Zur &Uuml;bersicht"
                            onClick={() => {
                                window.history.pushState({}, document.title, DASHBOARD);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return <ConnectionAssuranceCompensationRouting connectionAssurance={connectionAssurance} />;
};

import ConnectionAssuranceConnectionPlanFormView from '@/components/map/connection-assurance-connection-plan-form-view/ConnectionPlanFormView';
import { DASHBOARD } from '@/routes';
import { formatConnectionAssuranceProjectHeading } from '@/utils/formatting';
import { getAllPlantComponents } from '@/utils/getAllPlantComponents';
import useConnectionAssurance from '@/utils/useConnectionAssurance';
import { Button, CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

import ConnectionColumn from '../../components/overviewColumns/ConnectionColumn';
import GenerationColumn from '../../components/overviewColumns/GenerationColumn';
import ServiceColumn from '../../components/overviewColumns/ServiceColumn';
import TranspositionColumn from '../../components/overviewColumns/TranspositionColumn';

type ConnectionAssuranceDetailsViewProps = {
    connectionAssuranceId: string;
};

const ConnectionAssuranceDetailsView = ({ connectionAssuranceId }: ConnectionAssuranceDetailsViewProps) => {
    const { isLoading, connectionAssurance } = useConnectionAssurance(connectionAssuranceId);

    if (isLoading) {
        return <CenteredLoadingSpinner />;
    }

    return (
        <div className="w-full flex flex-col gap-y-12 py-10 relative">
            {connectionAssurance ? (
                <>
                    <h3 className="font-bold text-medium">
                        {formatConnectionAssuranceProjectHeading(connectionAssurance)}
                    </h3>

                    <p>
                        Nachfolgend sehen Sie die angegebenen Antragsdaten, die als Basis für die Anschlusszusage
                        dienen. Wenn sich die Daten im Nachhinein geändert haben, wird Ihnen das hier nicht angezeigt.
                    </p>

                    <div className="flex flex-col gap-y-4">
                        <div className="grid grid-flow-col gap-x-4 pb-4 grid-cols-3">
                            <ServiceColumn
                                service={connectionAssurance.service}
                                numberOfLocation={connectionAssurance.numberOfLocation}
                                location={connectionAssurance.location}
                                plantSize={connectionAssurance.plantSize}
                                oneOfManyLocations={connectionAssurance.oneOfManyLocations}
                            />
                            <GenerationColumn
                                generatorData={connectionAssurance.generatorData}
                                storageData={connectionAssurance.storageData}
                                allPlantComponents={getAllPlantComponents(
                                    connectionAssurance.plantComponents,
                                    connectionAssurance.plantComponentsGenerator,
                                    connectionAssurance.plantComponentsStorage,
                                )}
                                plantComponentsGenerator={connectionAssurance.plantComponentsGenerator}
                                plantComponentsStorage={connectionAssurance.plantComponentsStorage}
                                plantData={connectionAssurance.plantData}
                                smallestPlantComponentProperties={connectionAssurance.smallestPlantComponentProperties}
                                hasControllableConsumptionDevice={connectionAssurance.hasControllableConsumptionDevice}
                            />
                        </div>

                        <div className="grid grid-flow-col gap-x-4 pb-4 grid-cols-3">
                            <ConnectionColumn
                                connectionData={connectionAssurance.connectionDataProperties}
                                plantSize={connectionAssurance.plantSize}
                                numberOfLocation={connectionAssurance.numberOfLocation}
                                location={connectionAssurance.location}
                                generatorData={connectionAssurance.generatorData}
                            />

                            {connectionAssurance.plantSize !== 'smallest' && (
                                <TranspositionColumn
                                    showInstallerSection
                                    chosenInstaller={connectionAssurance.chosenInstaller}
                                    plantBuilder={connectionAssurance.plantData.plantBuilder}
                                    technicalOperationsManager={
                                        connectionAssurance.plantData.technicalOperationsManager
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <ConnectionAssuranceConnectionPlanFormView process={connectionAssurance} />
                </>
            ) : (
                <p>Die Details konnten leider nicht geladen werden. Bitte versuchen Sie es später erneut.</p>
            )}

            <Button
                label="Zurück"
                size="md"
                type="secondary"
                id="goBack"
                additionalClassName="mt-8"
                onClick={() => {
                    window.history.pushState({}, document.title, DASHBOARD);
                }}
            />
        </div>
    );
};
export default ConnectionAssuranceDetailsView;

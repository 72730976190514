import { generatePath } from 'react-router-dom';
import { EventObject, MachineConfig, StateMachine, StateNode, createMachine } from 'xstate';

import { SUBPROCESS, SubprocessMetaData, SubprocessValue } from './constants';
import { Context } from './context';

export const getStateNodes = (stateNode: StateNode | StateMachine<unknown, unknown, EventObject>): StateNode[] => {
    const { states } = stateNode;
    const nodes = Object.keys(states).reduce((accNodes: StateNode[], stateKey) => {
        const childStateNode = states[stateKey];
        const childStateNodes = getStateNodes(childStateNode);

        accNodes.push(childStateNode, ...childStateNodes);
        return accNodes;
    }, []);
    return nodes;
};

type RouteMetadata = { target: string } & SubprocessMetaData;
export const getRoutes = (config: MachineConfig<Partial<Context>, never, EventObject>): Array<RouteMetadata> => {
    const nodes = getStateNodes(createMachine(config));
    const routes: RouteMetadata[] = [];
    Object.values(nodes).forEach((node) => {
        if (node.meta && node.meta.path) {
            routes.push({
                target: `.${node.path.join('.')}`,
                ...node.meta,
            });
        }
    });
    return routes;
};

export const changeStepVisibility = (
    context: Partial<Context>,
    subprocess: SubprocessValue,
    visibility: boolean,
): void => {
    const [stepIndex, substepIndex] = SUBPROCESS[subprocess].processSubprocessIndex;
    context.meta.steps[stepIndex].visible.splice(substepIndex, 1, visibility);
};

export const updateProcessFunctionPath = (
    context: Partial<Context>,
    subprocess: SubprocessValue,
    newPath: string,
): void => {
    const [stepIndex, substepIndex] = SUBPROCESS[subprocess].processSubprocessIndex;
    const newProcessFunction = () => {
        context.meta.history.push(generatePath(newPath, { connectionAssuranceId: context.connectionAssuranceId }));
    };

    context.meta.steps[stepIndex].processFunctions.splice(substepIndex, 1, newProcessFunction);
};

import { CompensationDocumentsFileCategory } from '@/types/FileUpload';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: CompensationDocumentsFileCategory;
};

export function getCompensationDocumentsFileCategories(
    showRegistrationConfirmationGenerationPlant: boolean,
    showRegistrationConfirmationEnergyStorage: boolean,
): UploadFileCategory[] {
    const options: Option[] = [
        { label: 'Verbindliche Erklärung', value: 'bindingDeclaration' },
        { label: 'Erklärung Erhalt USt.', value: 'declarationOfReceiptOfVAT' },
        showRegistrationConfirmationGenerationPlant
            ? { label: 'Registrierungsbestätigung Erzeugungsanlage', value: 'registrationConfirmationGenerationPlant' }
            : undefined,
        showRegistrationConfirmationEnergyStorage
            ? {
                  label: 'Registrierungsbestätigung Energiespeichersystem',
                  value: 'registrationConfirmationEnergyStorage',
              }
            : undefined,

        { label: 'Sonstige', value: 'compensationOther' },
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef),
            tags: { category: 'compensationDocuments', subCategory: option.value },
        }));
}

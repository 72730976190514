import { PlantComponentGenerator, PlantComponentStorage } from '@/types/PlantComponent';
import { StorageData } from '@/types/StorageData';
import { formatNumber } from '@/utils/formatNumber';
import { CheckThick, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

type ControllableConsumptionDeviceColumnSectionProps = {
    storageData: StorageData;
    plantComponentsGenerator: PlantComponentGenerator[];
    plantComponentsStorage: PlantComponentStorage[];
};

export const ControllableConsumptionDeviceColumnSection = ({
    storageData,
    plantComponentsGenerator,
    plantComponentsStorage,
}: ControllableConsumptionDeviceColumnSectionProps) => {
    const activePower =
        storageData.energyStorageSystem === 'acSystem'
            ? plantComponentsStorage.reduce(
                  (sum, plantComponent) => sum + plantComponent.activePower * plantComponent.quantity,
                  0,
              )
            : plantComponentsGenerator.find((plantComponent) => plantComponent.usedForDcStorage)?.activePower;

    return (
        <ColumnSection title="Steuerbare Verbrauchseinrichtungen">
            <ColumnItem
                label="Auf Basis Ihrer Angaben werden folgende Infrastrukturen als steuerbare Verbrauchseinrichtung nach §14a EnWG deklariert."
                content={
                    <div className="flex flex-row pt-3">
                        <CheckThick data-testid="check" className="h-5 w-5 mt-0.5 fill-current text-primary" />
                        <span className="pl-3">
                            Energiespeicher mit Leistung {formatNumber(activePower)}
                            &nbsp;kW
                        </span>
                    </div>
                }
            />
        </ColumnSection>
    );
};

import { Saxony, StateCode } from '@/types/ConnectionAssuranceLocation';

type ElectricityNetworkOperation = 'EHS' | 'NGS' | 'ELS' | 'EST';
type GasNetworkOperation = 'EGA';

export type AddressLocation = {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country?: string;
};

export type CadastralDistrict = {
    stateCode: 'SN' | 'ST' | 'TH';
    districtId: string;
    districtName: string;
    cadastralDistrict?: string;
    cadastralUnitCounter?: string;
    cadastralUnitDenominator?: string;
};

export type BaseLocation = {
    isLandOwner: boolean;
    hasLandOwnerConsent?: boolean;
    networkOperation: ElectricityNetworkOperation | GasNetworkOperation;
    generalCadastralDistrictCoordinates: number[][][];
};

export type FullLocation = BaseLocation & (AddressLocation | CadastralDistrict);

export type BaseOfflineCadastralDistrict = {
    districtName: string;
    cadastralUnitCounter?: string | undefined | null;
    cadastralUnitDenominator?: string | undefined | null;
};

export type OfflineSaxonyCadastralDistrictLocation = BaseOfflineCadastralDistrict & {
    stateCode: Saxony;
};
export type OfflineSaxonyAnhaltOrThuringiaCadastralDistrictLocation = BaseOfflineCadastralDistrict & {
    stateCode: Exclude<StateCode, Saxony>;
    cadastralDistrict: string;
};

export type OfflineCadastralDistrictLocation =
    | OfflineSaxonyCadastralDistrictLocation
    | OfflineSaxonyAnhaltOrThuringiaCadastralDistrictLocation;

/**
 * OfflineLocationType is the same as LocationType, however all GIS related parameters are removed
 * because this Location will not be looked up and just accepted as it was typed in by the User
 */
export type OfflineLocationType = (AddressLocation | OfflineCadastralDistrictLocation) & {
    type: 'cadastral' | 'address';
};

export const isLocationAddress = (location: AddressLocation | CadastralDistrict): location is AddressLocation =>
    'city' in location;

import appConfig from '@/app.config';
import { ApiParams } from '@/hooks/useApi';
import { TechnicalOperationsManager } from '@/types/technicalOperationsManager';

type Parameter = {
    connectionAssuranceId: string;
    technicalOperationsManager: TechnicalOperationsManager;
};

const updateTechnicalOperationsManager = async ({ params, customerId, axios }: ApiParams<Parameter>): Promise<void> => {
    const { connectionAssuranceId, technicalOperationsManager } = params;

    return axios.patch(
        `${appConfig.services.contractApi}/account/${customerId}/connection-assurance/${connectionAssuranceId}/technical-operations-manager`,
        technicalOperationsManager,
    );
};

export default updateTechnicalOperationsManager;

import {
    CommissioningDataRequest,
    ContextGeneratorData,
    ContextStorageData,
    RequestGeneratorData,
    RequestStorageData,
} from '@/connection-assurance/commissioning/types/CommissioningData';
import { Context } from '@/connection-assurance/commissioning/wizard/context';
import { parseNumberFromString } from '@/utils/formatNumber';
import { germanDateToIsoDate } from '@/utils/validateDateInput';

import { transformPlantComponentsGenerator } from './transformPlantComponentsGenerator';

const convertGeneratorData = (generatorData: ContextGeneratorData): RequestGeneratorData => {
    if (!generatorData) {
        return undefined;
    }
    return {
        maStRGenerationPlant: generatorData.maStRGenerationPlant,
        solarModuleCapacityInkWp: generatorData.solarModuleCapacityInkWp
            ? parseNumberFromString(generatorData.solarModuleCapacityInkWp)
            : undefined,
    };
};

const convertStorageData = (storageData: ContextStorageData): RequestStorageData => {
    if (!storageData) {
        return undefined;
    }
    return {
        maStRGeneratorStorage: storageData.maStRGeneratorStorage,
        energyStorageCapacityInkWh: parseNumberFromString(storageData.energyStorageCapacityInkWh),
    };
};

export function transformContext(context: Partial<Context>): CommissioningDataRequest {
    if (!context.uploadFolderName) {
        throw new Error('Upload folder name is required');
    }
    return {
        technicalCommissioningDateOfGenerator: germanDateToIsoDate(context.technicalCommissioningDateOfGenerator),
        technicalCommissioningDateOfStorage: germanDateToIsoDate(context.technicalCommissioningDateOfStorage),
        acknowledgements: context.acknowledgements,
        plantComponentsGenerator: transformPlantComponentsGenerator(
            context.plantComponentsGenerator,
            context.plantComponentUsedForDcStorageId,
        ),
        plantComponentsStorage: context.plantComponentsStorage,
        generatorData: convertGeneratorData(context.generatorData),
        storageData: convertStorageData(context.storageData),
        electricityMeterSerialNumber: context.electricityMeterSerialNumber,
        uploadFolderName: context.uploadFolderName,
    };
}

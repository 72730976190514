import { GenerationPlantData } from '@/connection-assurance/compensation/types/GenerationPlantData';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { TextField } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';

type Props = {
    dateLabel: string;
    id: FieldPath<GenerationPlantData>;
    register: UseFormRegister<GenerationPlantData>;
    validateDateInput: (input: string) => string | undefined;
    hint?: React.ReactNode;
    displayErrorMessage?: string;
};

export const CompensationDateFormItem = ({
    dateLabel,
    id,
    register,
    displayErrorMessage,
    validateDateInput,
    hint,
}: Props) => (
    <div className="flex justify-center">
        <div className="w-full">
            <div className="w-3/12">
                <TextField
                    name={id}
                    label={dateLabel}
                    placeholder="tt.mm.jjjj"
                    {...register(id, {
                        validate: validateDateInput,
                        shouldUnregister: true,
                        required: REQUIRED_ERROR_MESSAGE,
                    })}
                    size={10}
                    displayErrorMessage={displayErrorMessage}
                />
            </div>
            {hint}
        </div>
    </div>
);

import { CommissioningGenerationPlantDocumentsFileCategory } from '@/types/FileUpload';
import { PlantSize } from '@/types/PlantSize';
import { InfoI, StyledLink } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

import PrintOutLink from '../PrintOutLink';

export default (
    fileCategory: CommissioningGenerationPlantDocumentsFileCategory,
    boundaryDefiningParentRef: MutableRefObject<HTMLElement>,
    plantSize: PlantSize,
) => {
    const requestIsSmallCapacity = plantSize === 'smallest' || plantSize === 'small';

    switch (fileCategory) {
        case 'commissioningDisplay':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Anschluss/ANS_Anmeldung_Formblatt_Strom.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Anzeige zur Inbetriebsetzung auf dem Formular „Anmeldung zum Netzanschluss“ mit
                                    Datum und Unterschrift durch den aufgeführten Elektrofachbetrieb.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );
        case 'builderAndOperationReadinessDeclaration':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td29_betriebsbereitschaftserklaerung_dguv.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Errichtererklärung und Betriebsbereitschaftserklärung nach DGUV Vorschrift 3.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );

        case 'protectionTestProtocol':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Schutzprüfprotokolle der Schutztechnik der Erzeugungseinheiten.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );

        case 'commissioningProtocol':
            return requestIsSmallCapacity ? (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/AN002_Inbetriebsetzungsprotokoll_bis_30kW.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Inbetriebsetzungsprotokoll für Erzeugungsanlagen/Speicher.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            ) : (
                <>
                    <br />
                    (hier finden Sie den Vordruck für die{' '}
                    <StyledLink
                        target="_blank"
                        href="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/AN003_Inbetriebsetzungsprotokoll_NS_groesser_30kW.pdf"
                    >
                        Niederspannung
                    </StyledLink>{' '}
                    und für die{' '}
                    <StyledLink
                        target="_blank"
                        href="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/AN004_Inbetriebsetzungsprotokoll_MS.pdf"
                    >
                        Mittelspannung
                    </StyledLink>
                    )
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Inbetriebsetzungsprotokoll für Erzeugungsanlagen/Speicher.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );

        case 'generationPlantPhotoDocumentation':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Fotos vom Typenschild des Wechselrichters sowie der Solarmodulflächen.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );

        default:
            return undefined;
    }
};

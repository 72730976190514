import { CompanyName } from '@/types/PlantData';
import { flattenErrors } from '@/utils/flattenErrors';
import { registerWrapper } from '@/utils/formHookHelper';
import { REQUIRED_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE } from '@/utils/textConstants';
import { TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export type CompanyNameFieldProps = {
    registerPrefix: string;
    disabled?: boolean;
    companyNameOptions?: {
        minLength?: number;
        maxLength?: number;
    };
};

const CompanyNameField = ({
    registerPrefix,
    disabled = false,
    companyNameOptions,
}: CompanyNameFieldProps): React.ReactElement => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="h-20">
            <TextField
                autoComplete="organization"
                name="companyName"
                label="Firmenname inkl. Rechtsform"
                displayErrorMessage={flattenErrors<CompanyName>(registerPrefix, errors).companyName?.message}
                disabled={disabled}
                {...registerWrapper(register, `${registerPrefix}.companyName`, {
                    required: REQUIRED_ERROR_MESSAGE,
                    minLength: { value: companyNameOptions?.minLength ?? 2, message: VALIDATION_ERROR_MESSAGE },
                    maxLength: { value: companyNameOptions?.maxLength ?? 100, message: VALIDATION_ERROR_MESSAGE },
                })}
            />
        </div>
    );
};

export default CompanyNameField;

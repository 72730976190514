import { MachineConfig } from 'xstate';

import { SUBPROCESS } from './constants';
import { Context } from './context';
import {
    SYNC_LOCATION_WITH_STATE,
    SYNC_STATE_WITH_LOCATION,
    UPDATE_CONTEXT,
    UPDATE_FURTHEST_STEPS,
    UPDATE_PATH,
    UPDATE_WIZARD_STEPS_VISIBILITY,
    UserEvent,
} from './events';
import guards from './guards';
import { getLocalStorageEntryName } from './wizard';

const initial = 'initial';

const states = {
    initial: {
        always: [{ target: 'plant' }],
    },
    plant: {
        initial,
        states: {
            initial: {
                always: [{ target: 'compensationAgreement' }],
            },
            compensationAgreement: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.PLANT_COMPENSATION_AGREEMENT,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.PLANT_COMPENSATION_AGREEMENT,
                    },
                ],
                exit: [
                    {
                        type: UPDATE_WIZARD_STEPS_VISIBILITY,
                    },
                ],
                meta: {
                    ...SUBPROCESS.PLANT_COMPENSATION_AGREEMENT,
                },
                on: {
                    NEXT: [
                        { target: '#completion', cond: guards.isCompensationRejected },
                        { target: 'locationData', cond: guards.isLocationDataShown },
                        { target: 'storagePlantData', cond: guards.isStoragePlantDataShown },
                        { target: 'existingGenerationPlant' },
                    ],
                },
            },
            locationData: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.PLANT_LOCATION_DATA,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.PLANT_LOCATION_DATA,
                    },
                ],
                exit: [
                    {
                        type: UPDATE_WIZARD_STEPS_VISIBILITY,
                    },
                ],
                meta: {
                    ...SUBPROCESS.PLANT_LOCATION_DATA,
                },
                on: {
                    NEXT: [
                        { target: 'generationPlantData', cond: guards.isGenerationPlantDataShown },
                        { target: 'storagePlantData', cond: guards.isStoragePlantDataShown },
                        { target: 'existingGenerationPlant' },
                    ],
                    PREV: { target: 'compensationAgreement' },
                },
            },
            generationPlantData: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.PLANT_GENERATION_PLANT_DATA,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.PLANT_GENERATION_PLANT_DATA,
                    },
                ],
                meta: {
                    ...SUBPROCESS.PLANT_GENERATION_PLANT_DATA,
                },
                on: {
                    NEXT: [
                        { target: 'storagePlantData', cond: guards.isStoragePlantDataShown },
                        { target: 'existingGenerationPlant' },
                    ],
                    PREV: { target: 'locationData' },
                },
            },
            storagePlantData: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.PLANT_STORAGE_PLANT_DATA,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.PLANT_STORAGE_PLANT_DATA,
                    },
                ],
                meta: {
                    ...SUBPROCESS.PLANT_STORAGE_PLANT_DATA,
                },
                on: {
                    NEXT: { target: 'existingGenerationPlant' },
                    PREV: [
                        { target: 'generationPlantData', cond: guards.isGenerationPlantDataShown },
                        { target: 'locationData', cond: guards.isLocationDataShown },
                        { target: 'compensationAgreement' },
                    ],
                },
            },
            existingGenerationPlant: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.PLANT_EXISTING_GENERATION_PLANT,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.PLANT_EXISTING_GENERATION_PLANT,
                    },
                ],
                meta: {
                    ...SUBPROCESS.PLANT_EXISTING_GENERATION_PLANT,
                },
                on: {
                    NEXT: { target: 'existingSolarPlant', cond: guards.isExistingSolarPlantShown },
                    PREV: [
                        { target: 'storagePlantData', cond: guards.isStoragePlantDataShown },
                        { target: 'generationPlantData', cond: guards.isGenerationPlantDataShown },
                        { target: 'locationData', cond: guards.isLocationDataShown },
                        { target: 'compensationAgreement' },
                    ],
                },
            },
            existingSolarPlant: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.PLANT_EXISTING_SOLAR_PLANT,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.PLANT_EXISTING_SOLAR_PLANT,
                    },
                ],
                meta: {
                    ...SUBPROCESS.PLANT_EXISTING_SOLAR_PLANT,
                },
                on: {
                    PREV: [{ target: 'existingGenerationPlant' }],
                },
            },
            tail: {
                always: [
                    { target: 'compensationAgreement', cond: guards.isCompensationRejected },
                    { target: 'existingSolarPlant', cond: guards.isExistingSolarPlantShown },
                    { target: 'existingGenerationPlant' },
                ],
            },
        },
        on: {
            NEXT: [{ target: 'compensation', cond: guards.isAnyCompensationStepShown }, { target: 'documents' }],
        },
    },

    compensation: {
        initial,
        states: {
            initial: {
                always: [
                    {
                        target: 'landlordToTenantsElectricityBonus',
                        cond: guards.isLandlordToTenantsElectricityBonusShown,
                    },
                    { target: 'increasedCompensation', cond: guards.isIncreasedCompensationShown },
                    { target: 'bankAccountDetails', cond: guards.isBankAccountDetailsShown },
                    { target: 'salesTax', cond: guards.isSalesTaxShown },
                ],
            },
            landlordToTenantsElectricityBonus: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
                    },
                ],
                meta: {
                    ...SUBPROCESS.LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
                },
                on: {
                    NEXT: [
                        { target: 'bankAccountDetails', cond: guards.isBankAccountDetailsShown },
                        { target: 'salesTax', cond: guards.isSalesTaxShown },
                    ],
                },
            },
            increasedCompensation: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.COMPENSATION_INCREASED_COMPENSATION,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.COMPENSATION_INCREASED_COMPENSATION,
                    },
                ],
                meta: {
                    ...SUBPROCESS.COMPENSATION_INCREASED_COMPENSATION,
                },
                on: {
                    NEXT: [{ target: 'bankAccountDetails', cond: guards.isBankAccountDetailsShown }],
                },
            },
            bankAccountDetails: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.COMPENSATION_BANK_ACCOUNT_DETAILS,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.COMPENSATION_BANK_ACCOUNT_DETAILS,
                    },
                ],
                meta: {
                    ...SUBPROCESS.COMPENSATION_BANK_ACCOUNT_DETAILS,
                },
                on: {
                    NEXT: { target: 'salesTax' },
                    PREV: [
                        {
                            target: 'landlordToTenantsElectricityBonus',
                            cond: guards.isLandlordToTenantsElectricityBonusShown,
                        },
                        { target: 'increasedCompensation', cond: guards.isIncreasedCompensationShown },
                    ],
                },
            },
            salesTax: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.COMPENSATION_SALES_TAX,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.COMPENSATION_SALES_TAX,
                    },
                ],
                meta: {
                    ...SUBPROCESS.COMPENSATION_SALES_TAX,
                },
                on: {
                    PREV: { target: 'bankAccountDetails' },
                },
            },
            tail: {
                always: [
                    { target: 'salesTax', cond: guards.isSalesTaxShown },
                    { target: 'increasedCompensation', cond: guards.isIncreasedCompensationShown },
                    {
                        target: 'landlordToTenantsElectricityBonus',
                        cond: guards.isLandlordToTenantsElectricityBonusShown,
                    },
                ],
            },
        },
        on: {
            NEXT: { target: 'documents' },
            PREV: { target: 'plant.tail' },
        },
    },

    documents: {
        initial,
        states: {
            initial: {
                always: { target: 'documentUpload' },
            },
            documentUpload: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        ...SUBPROCESS.DOCUMENT_UPLOAD,
                    },
                    {
                        type: UPDATE_PATH,
                        ...SUBPROCESS.DOCUMENT_UPLOAD,
                    },
                ],
                meta: {
                    ...SUBPROCESS.DOCUMENT_UPLOAD,
                },
            },
            tail: {
                always: { target: 'documentUpload' },
            },
        },
        on: {
            NEXT: { target: 'completion' },
            PREV: [{ target: 'compensation.tail', cond: guards.isAnyCompensationStepShown }, { target: 'plant.tail' }],
        },
    },

    completion: {
        id: 'completion',
        entry: [
            {
                type: UPDATE_FURTHEST_STEPS,
                ...SUBPROCESS.COMPLETION,
            },
            {
                type: UPDATE_PATH,
                ...SUBPROCESS.COMPLETION,
            },
        ],
        meta: {
            ...SUBPROCESS.COMPLETION,
        },
        on: {
            NEXT: { target: 'nextSteps' },
            PREV: [{ target: 'documents.tail', cond: guards.isCompensationAgreed }, { target: 'plant.tail' }],
        },
    },

    nextSteps: {
        entry: [
            {
                type: UPDATE_FURTHEST_STEPS,
                ...SUBPROCESS.NEXT_STEPS,
            },
            {
                type: UPDATE_PATH,
                ...SUBPROCESS.NEXT_STEPS,
            },
        ],
        meta: {
            ...SUBPROCESS.NEXT_STEPS,
        },
    },
};

export const getConfig = (connectionAssuranceId: string) =>
    ({
        id: getLocalStorageEntryName(connectionAssuranceId),
        initial,
        context: {},
        on: {
            UPDATE_CONTEXT: {
                actions: [UPDATE_CONTEXT],
            },
            SYNC_STATE_WITH_LOCATION: {
                actions: [SYNC_STATE_WITH_LOCATION],
            },
            SYNC_LOCATION_WITH_STATE: {
                actions: [SYNC_LOCATION_WITH_STATE],
            },
            UPDATE_PATH: {
                actions: [UPDATE_PATH],
            },
            UPDATE_WIZARD_STEPS_VISIBILITY: {
                actions: [UPDATE_WIZARD_STEPS_VISIBILITY],
            },
        },
        states,
        strict: true,
        predictableActionArguments: true,
    }) as MachineConfig<Partial<Context>, never, UserEvent>;

export const DASHBOARD = '/uebersicht';

export const ROOT_CONTRACT = '/vertrag';

export const CONTRACT_VIEW = '/:customerId/:processCommunicationId/vertragsansicht';
export const CONTRACT_INSTALLER_SELECTION = '/:customerId/:processCommunicationId/installateur';
export const CONTRACT_DOCUMENTS = '/:processCommunicationId/dokumente';
export const CONTRACT_DOCUMENTS_UPLOAD = '/:processCommunicationId/dokumente-hochladen';
export const CANCEL_CONTRACT = '/:customerId/:processCommunicationId/kuendigung';
export const CANCEL_CONTRACT_CONFIRMATION = '/:customerId/:processCommunicationId/kuendigungsbestaetigung';

export const INSTALLER = `/installateur`;
export const CONTRACT_COMMISSIONING_DATA = `/:processCommunicationId/inbetriebsetzungsdaten`;
export const CONTRACT_FINISH_COMMISSIONING = `/:processCommunicationId/fertigstellungsanzeige`;
export const CONTRACT_DETAILS_INSTALLER = `${INSTALLER}/:processCommunicationId/vertragsdetails`;

export const CONNECTION_ASSURANCE = '/anschlusszusage/:connectionAssuranceId';
export const CONNECTION_ASSURANCE_DETAILS = `${CONNECTION_ASSURANCE}/details`;
export const CONNECTION_ASSURANCE_DOCUMENTS = `${CONNECTION_ASSURANCE}/dokumente`;
export const CONNECTION_ASSURANCE_DOCUMENTS_UPLOAD = `${CONNECTION_ASSURANCE}/dokumenten-upload`;
export const CONNECTION_ASSURANCE_INSTALLER_SELECTION = `${CONNECTION_ASSURANCE}/installateur-auswahl`;
export const CONNECTION_ASSURANCE_TECHNICAL_OPERATIONS_MANAGER_SELECTION = `${CONNECTION_ASSURANCE}/technischer-betriebsfuehrer-auswahl`;

export const CONNECTION_ASSURANCE_COMMISSIONING = `${CONNECTION_ASSURANCE}/inbetriebsetzung`;
export const CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS = `${CONNECTION_ASSURANCE_COMMISSIONING}/anlagenkomponenten`;
export const CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR = `${CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS}/erzeugungsanlage`;
export const CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE = `${CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS}/energiespeicher`;
export const CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD = `${CONNECTION_ASSURANCE_COMMISSIONING}/dokumente`;
export const CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION = `${CONNECTION_ASSURANCE_COMMISSIONING}/abschlussbestaetigung`;
export const CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS = `${CONNECTION_ASSURANCE_COMMISSIONING}/naechste-schritte`;

export const CONNECTION_ASSURANCE_COMPENSATION = `${CONNECTION_ASSURANCE}/verguetungseinstufung`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT = `${CONNECTION_ASSURANCE_COMPENSATION}/anlage`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION = `${CONNECTION_ASSURANCE_COMPENSATION_PLANT}/verguetung`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA = `${CONNECTION_ASSURANCE_COMPENSATION_PLANT}/standortdaten`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA = `${CONNECTION_ASSURANCE_COMPENSATION_PLANT}/erzeugungsanlage`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA = `${CONNECTION_ASSURANCE_COMPENSATION_PLANT}/energiespeicher`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT = `${CONNECTION_ASSURANCE_COMPENSATION_PLANT}/bestehende-erzeugungsanlagen`;
export const CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT = `${CONNECTION_ASSURANCE_COMPENSATION_PLANT}/bestehende-solaranlagen`;

export const CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION = `${CONNECTION_ASSURANCE_COMPENSATION}/verguetung`;
export const CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS = `${CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION}/mieterstromzuschlag`;
export const CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION = `${CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION}/erhoehte-verguetung`;
export const CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS = `${CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION}/kontodaten`;
export const CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX = `${CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION}/umsatzsteuer`;

export const CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD = `${CONNECTION_ASSURANCE_COMPENSATION}/dokumente`;

export const CONNECTION_ASSURANCE_COMPENSATION_COMPLETION = `${CONNECTION_ASSURANCE_COMPENSATION}/abschluss`;

export const CONNECTION_ASSURANCE_COMPENSATION_NEXT_STEPS = `${CONNECTION_ASSURANCE_COMPENSATION}/naechste-schritte`;

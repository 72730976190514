import { Context } from './context';

export const isCompensationAgreed = (context: Partial<Context>): boolean => context.compensationAgreed === 'yes';

export const isCompensationRejected = (context: Partial<Context>): boolean => context.compensationAgreed === 'no';

export const isLocationDataShown = (context: Partial<Context>): boolean => context.isSolarGeneratorType;

export const isGenerationPlantDataShown = (context: Partial<Context>): boolean =>
    context.isSolarGeneratorType &&
    (!context.generatorLocations?.length || context.generatorLocations.includes('building'));

export const isStoragePlantDataShown = (context: Partial<Context>): boolean => context.hasStorageData;

export const isExistingSolarPlantShown = (context: Partial<Context>): boolean => context.isSolarGeneratorType;

export const isLandlordToTenantsElectricityBonusShown = (context: Partial<Context>): boolean =>
    context.surplusPowerFeedIn &&
    (!context.generatorLocations?.length || context.generatorLocations.includes('building'));

export const isIncreasedCompensationShown = (context: Partial<Context>): boolean =>
    context.isSolarGeneratorType &&
    !context.surplusPowerFeedIn &&
    (!context.generatorLocations?.length || context.generatorLocations.includes('building'));

export const isBankAccountDetailsShown = (context: Partial<Context>): boolean =>
    context.customerType === 'private' || context.customerType === 'company';

export const isSalesTaxShown = (context: Partial<Context>): boolean =>
    context.customerType === 'private' || context.customerType === 'company';

export const isAnyCompensationStepShown = (context: Partial<Context>): boolean =>
    isLandlordToTenantsElectricityBonusShown(context) ||
    isIncreasedCompensationShown(context) ||
    isBankAccountDetailsShown(context) ||
    isSalesTaxShown(context);

export default {
    isCompensationAgreed,
    isCompensationRejected,
    isLocationDataShown,
    isGenerationPlantDataShown,
    isStoragePlantDataShown,
    isExistingSolarPlantShown,
    isLandlordToTenantsElectricityBonusShown,
    isIncreasedCompensationShown,
    isBankAccountDetailsShown,
    isSalesTaxShown,
    isAnyCompensationStepShown,
};

import { ALL_STEPS } from './constants';

// To satisfy wizards requirements, subprocess should start with 1.
// The index 0 is reserved only for active Process with inactive subprocess.
export const getFurthestStepsForWizard = (furthestProcessSubProcessIndex: [number, number]): [string, number] => {
    if (furthestProcessSubProcessIndex[0] >= ALL_STEPS.length) {
        throw Error('Out of bounds');
    }
    if (furthestProcessSubProcessIndex[0] === ALL_STEPS.length - 1) {
        return [ALL_STEPS[furthestProcessSubProcessIndex[0]], furthestProcessSubProcessIndex[1]];
    }
    return [ALL_STEPS[furthestProcessSubProcessIndex[0]], furthestProcessSubProcessIndex[1] + 1];
};

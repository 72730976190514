import { ConnectionAssuranceInstaller } from '@/types/ConnectionAssuranceInstaller';
import { Address, Contact, PlantBuilder, TechnicalOperationsManager } from '@/types/PlantData';
import { formatContractAddress, formatTelephoneNumber } from '@/utils/formatting';
import { ColumnItem, ColumnSection, MultiColumn, SubColumn } from '@ten-netzkundenportal/ui-components';
import React from 'react';

export interface TranspositionColumnProps {
    showInstallerSection: boolean;
    chosenInstaller?: ConnectionAssuranceInstaller;
    plantBuilder?: PlantBuilder;
    technicalOperationsManager?: TechnicalOperationsManager;
}

const getInstallerAddressText = (chosenInstaller: ConnectionAssuranceInstaller): string | string[] => {
    if (chosenInstaller) {
        return [`${chosenInstaller.companyName}`, ...formatContractAddress(chosenInstaller.address)];
    }
    return '';
};

const getAddressText = (companyName: string, address: Address): string[] => [
    companyName,
    ...formatContractAddress(address),
];

const getContactText = (contact: Contact, email?: string): string[] => [
    `${contact.firstName} ${contact.lastName}`,
    formatTelephoneNumber(contact.telephone),
    email,
];

const TranspositionColumn = ({
    showInstallerSection,
    chosenInstaller,
    plantBuilder,
    technicalOperationsManager,
}: TranspositionColumnProps): React.ReactElement => (
    <div className="col-span-2">
        <MultiColumn title="Umsetzung">
            <SubColumn>
                {showInstallerSection && (
                    <ColumnSection title="Installateur">
                        {chosenInstaller ? (
                            <ColumnItem label="Adresse" text={getInstallerAddressText(chosenInstaller)} />
                        ) : (
                            <p className="italic">keine Angabe</p>
                        )}
                    </ColumnSection>
                )}

                <ColumnSection title="Projektpartner">
                    {plantBuilder ? (
                        <>
                            <ColumnItem
                                label="Adresse"
                                text={getAddressText(plantBuilder.companyName, plantBuilder.address)}
                            />
                            <ColumnItem label="Ansprechpartner" text={getContactText(plantBuilder.contact)} />
                        </>
                    ) : (
                        <p className="italic">keine Angabe</p>
                    )}
                </ColumnSection>
            </SubColumn>

            <SubColumn>
                <ColumnSection title="Technischer Betriebsführer">
                    {technicalOperationsManager ? (
                        <>
                            <ColumnItem
                                label="Adresse"
                                text={getAddressText(
                                    technicalOperationsManager.companyName,
                                    technicalOperationsManager.address,
                                )}
                            />
                            <ColumnItem
                                label="Ansprechpartner"
                                text={getContactText(
                                    technicalOperationsManager.contact,
                                    technicalOperationsManager.email,
                                )}
                            />
                        </>
                    ) : (
                        <p className="italic">keine Angabe</p>
                    )}
                </ColumnSection>
            </SubColumn>
        </MultiColumn>
    </div>
);

export default TranspositionColumn;

import { InitialContextCompensationData } from '@/connection-assurance/compensation/types/CompensationData';
import {
    CONNECTION_ASSURANCE_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX,
    CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD,
    CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA,
    CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA,
} from '@/routes';
import { ConnectionAssurance } from '@/types';
import { getCustomerType } from '@/utils/account';
import { debounce } from '@/utils/debounce';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Step } from '@ten-netzkundenportal/ui-components';
import { useMachine } from '@xstate/react';
import H from 'history';
import * as React from 'react';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import { AnyStateMachine, Interpreter, StateFrom, createMachine } from 'xstate';

import actions from './actions';
import { STEPS, SUBPROCESS } from './constants';
import { Context } from './context';
import { SYNC_LOCATION_WITH_STATE, SYNC_STATE_WITH_LOCATION, UserEvent } from './events';
import guards from './guards';
import { getConfig } from './machine';
import { changeStepVisibility, getRoutes } from './util';

export const getLocalStorageEntryName = (connectionAssuranceId: string) => {
    const localStoragePrefix = 'einspeiser-verguetung';
    return `${localStoragePrefix}-${connectionAssuranceId}`;
};

export const createInitialContext = (
    connectionAssurance: ConnectionAssurance,
    accounts: AccountInfo[],
): InitialContextCompensationData => {
    const customerType = getCustomerType(accounts);

    return {
        isSolarGeneratorType: connectionAssurance.generatorData?.generalGeneratorType.generatorType === 'solarEnergy',
        hasStorageData: !!connectionAssurance.storageData,
        surplusPowerFeedIn: connectionAssurance.generatorData?.surplusPowerFeedIn,
        customerType,
        connectionAssuranceId: connectionAssurance.id,
        processCommunicationId: connectionAssurance.processCommunicationId,
    };
};

const createInitialSteps = (
    history: H.History,
    connectionAssurance: ConnectionAssurance,
    initialContext: InitialContextCompensationData,
) => {
    const processFunction = (path) => () => {
        history.push(path);
    };

    const step1: Step = {
        label: 'Anlage',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_PLANT_COMPENSATION, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_PLANT_LOCATION_DATA, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_PLANT_GENERATION_PLANT_DATA, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_PLANT_STORAGE_PLANT_DATA, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_GENERATION_PLANT, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_PLANT_EXISTING_SOLAR_PLANT, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: [
            'Vergütung',
            'Standortdaten',
            'Erzeugungsanlage',
            'Energiespeicher',
            'Bestehende Erzeugungsanlagen',
            'Bestehende Solaranlagen',
        ],
        processName: STEPS.PLANT,
        visible: [
            true,
            guards.isLocationDataShown(initialContext),
            guards.isGenerationPlantDataShown(initialContext),
            guards.isStoragePlantDataShown(initialContext),
            true,
            guards.isExistingSolarPlantShown(initialContext),
        ],
    };

    const step2: Step = {
        label: 'Vergütung',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_LANDLORD_TO_TENANTS_ELECTRICITY_BONUS, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_INCREASED_COMPENSATION, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_BANK_ACCOUNT_DETAILS, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_COMPENSATION_SALES_TAX, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: ['Mieterstromzuschlag', 'Erhöte Vergütung', 'Kontodaten', 'Umsatzsteuer'],
        processName: STEPS.COMPENSATION,
        visible: [
            guards.isLandlordToTenantsElectricityBonusShown(initialContext),
            guards.isIncreasedCompensationShown(initialContext),
            guards.isBankAccountDetailsShown(initialContext),
            guards.isSalesTaxShown(initialContext),
        ],
    };

    const step3: Step = {
        label: 'Dokumente',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION_DOCUMENT_UPLOAD, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: ['Dokumentenupload'],
        processName: STEPS.DOCUMENT_UPLOAD,
        visible: [true],
    };

    const step4: Step = {
        label: 'Abschluss',
        processFunctions: [
            processFunction(
                generatePath(CONNECTION_ASSURANCE_COMPENSATION, {
                    connectionAssuranceId: connectionAssurance.id,
                }),
            ),
        ],
        steplabels: ['Abschluss'],
        processName: STEPS.COMPLETION,
        visible: [true],
    };

    const step5: Step = {
        label: 'Nächste Schritte',
        processFunctions: [],
        steplabels: [],
        processName: STEPS.NEXT_STEPS,
        visible: [true],
    };

    return [step1, step2, step3, step4, step5];
};

export const setWaiverStepVisibility = (context: Context) => {
    changeStepVisibility(context, 'PLANT_LOCATION_DATA', false);
    changeStepVisibility(context, 'PLANT_GENERATION_PLANT_DATA', false);
    changeStepVisibility(context, 'PLANT_STORAGE_PLANT_DATA', false);
    changeStepVisibility(context, 'PLANT_EXISTING_GENERATION_PLANT', false);
    changeStepVisibility(context, 'PLANT_EXISTING_SOLAR_PLANT', false);

    changeStepVisibility(context, 'LANDLORD_TO_TENANTS_ELECTRICITY_BONUS', false);
    changeStepVisibility(context, 'COMPENSATION_INCREASED_COMPENSATION', false);
    changeStepVisibility(context, 'COMPENSATION_BANK_ACCOUNT_DETAILS', false);
    changeStepVisibility(context, 'COMPENSATION_SALES_TAX', false);

    changeStepVisibility(context, 'DOCUMENT_UPLOAD', false);
};

export const setInitialStepVisibility = (context: Context) => {
    changeStepVisibility(context, 'PLANT_LOCATION_DATA', guards.isLocationDataShown(context));
    changeStepVisibility(context, 'PLANT_GENERATION_PLANT_DATA', guards.isGenerationPlantDataShown(context));
    changeStepVisibility(context, 'PLANT_STORAGE_PLANT_DATA', guards.isStoragePlantDataShown(context));
    changeStepVisibility(context, 'PLANT_EXISTING_GENERATION_PLANT', true);
    changeStepVisibility(context, 'PLANT_EXISTING_SOLAR_PLANT', guards.isExistingSolarPlantShown(context));

    changeStepVisibility(
        context,
        'LANDLORD_TO_TENANTS_ELECTRICITY_BONUS',
        guards.isLandlordToTenantsElectricityBonusShown(context),
    );
    changeStepVisibility(context, 'COMPENSATION_INCREASED_COMPENSATION', guards.isIncreasedCompensationShown(context));
    changeStepVisibility(context, 'COMPENSATION_BANK_ACCOUNT_DETAILS', guards.isBankAccountDetailsShown(context));
    changeStepVisibility(context, 'COMPENSATION_SALES_TAX', guards.isSalesTaxShown(context));

    changeStepVisibility(context, 'DOCUMENT_UPLOAD', true);
};

export const createWizardMachine = (
    history: H.History,
    currentPath: string,
    connectionAssurance: ConnectionAssurance,
    accounts: AccountInfo[],
) => {
    const config = getConfig(connectionAssurance.id);
    const routes = getRoutes(config).map((route) => ({
        target: route.target,
        cond: (context, event) =>
            event.path &&
            matchPath(event.path, { path: route.path, exact: true, strict: false }) &&
            context.meta.path !== route.path,
    }));
    const initialContext = createInitialContext(connectionAssurance, accounts);
    const rehydratedConfig = JSON.parse(localStorage.getItem(getLocalStorageEntryName(connectionAssurance.id))) || {
        meta: { furthestProcessSubProcessIndex: SUBPROCESS.PLANT_COMPENSATION_AGREEMENT.processSubprocessIndex },
        ...initialContext,
    };
    const machine = {
        ...config,
        ...{
            context: {
                ...rehydratedConfig,
                ...{ meta: { ...rehydratedConfig.meta, ...{ history, path: currentPath } } },
            },
        },
    };
    machine.on[SYNC_STATE_WITH_LOCATION] = routes;
    machine.context.meta.steps = createInitialSteps(history, connectionAssurance, initialContext);

    return createMachine<Partial<Context>, UserEvent>(machine, { guards, actions });
};

export const useWizardMachine = (
    connectionAssurance: ConnectionAssurance,
): [
    StateFrom<AnyStateMachine>,
    Interpreter<Partial<Context>, unknown, UserEvent>['send'],
    Interpreter<Partial<Context>, unknown, UserEvent>,
] => {
    const location = useLocation();
    const history = useHistory();
    const { accounts } = useMsal();

    const machine: AnyStateMachine = React.useMemo(
        () => createWizardMachine(history, location?.pathname, connectionAssurance, accounts),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const [state, send, service] = useMachine(machine);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLocalStorage = React.useCallback(
        debounce((context: Partial<Context>) => {
            const storedContext = {
                ...context,
                ...{
                    meta: {
                        ...context.meta,
                        ...{ steps: {} },
                    },
                },
            };
            localStorage.setItem(getLocalStorageEntryName(connectionAssurance.id), JSON.stringify(storedContext));
        }, 500),
        [],
    );

    React.useEffect(() => {
        updateLocalStorage(state.context);
    }, [state.context, updateLocalStorage]);

    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (
                statePath !== undefined &&
                location?.pathname !== statePath &&
                location?.pathname.startsWith(
                    generatePath(CONNECTION_ASSURANCE_COMPENSATION, { connectionAssuranceId: connectionAssurance.id }),
                )
            ) {
                send({ type: SYNC_STATE_WITH_LOCATION, path: location?.pathname });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location?.pathname],
    );

    React.useEffect(
        () => {
            const statePath = state.context?.meta.path;
            if (
                statePath !== undefined &&
                location?.pathname !== statePath &&
                location?.pathname.startsWith(
                    generatePath(CONNECTION_ASSURANCE_COMPENSATION, { connectionAssuranceId: connectionAssurance.id }),
                )
            ) {
                send({ type: SYNC_LOCATION_WITH_STATE });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.context?.meta.path],
    );

    return [state, send, service];
};

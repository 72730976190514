import { MasterDataRegisterNumberField } from '@/connection-assurance/commissioning/components/MasterDataRegisterNumberField';
import { PlantComponentsTable } from '@/connection-assurance/commissioning/components/PlantComponentsTable';
import { RouteProperties } from '@/connection-assurance/commissioning/wizard/RouteProperties';
import { formatNumber, parseNumberFromString } from '@/utils/formatNumber';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { plantSizeIsAtMostOfSmallSize } from '@/utils/plantSize';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { validateNumberInput } from '@/utils/validation';
import {
    Form,
    FormItem,
    FormLayout,
    FormSubTitle,
    TeagPiktoGaststoerung800X800210816,
    TextField,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CommissioningProcessProps, PlantComponentProps } from '../CommissioningProcessProps';
import { ElectricityMeterSerialNumberField } from '../components/ElectricityMeterSerialNumberField';
import { EnergyStorageCapacityErrorText } from '../components/EnergyStorageCapacityErrorText';
import { Context } from '../wizard/context';
import { isPlantComponentsGeneratorShown } from '../wizard/guards';
import { useContextUpdate } from '../wizard/hooks';

export const PlantComponentsStorage = ({
    onSubmit,
    goBack,
    context,
    title,
    allPlantComponents,
    updateContext,
    connectionAssurance,
}: RouteProperties & CommissioningProcessProps & PlantComponentProps) => {
    const reactHookForm = useForm<Context>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            storageData: {
                energyStorageCapacityInkWh: context.storageData?.energyStorageCapacityInkWh,
                maStRGeneratorStorage: context.storageData?.maStRGeneratorStorage,
            },
            electricityMeterSerialNumber: context.electricityMeterSerialNumber,
        },
    });

    const reactHookFormPlantComponents = useForm<Context>({
        mode: 'onTouched',
        shouldUnregister: true,
    });

    const {
        formState: { isValid, errors },
        register,
        watch,
    } = reactHookForm;

    const {
        formState: { isValid: isPlantComponentValid },
    } = reactHookFormPlantComponents;

    const newActivePower = calculateSumOfActivePowersInKW(context.plantComponentsStorage ?? []);
    const oldActivePower = calculateSumOfActivePowersInKW(connectionAssurance.plantComponentsStorage ?? []);

    const [maximumEnergyStorageCapacityExceeded, setMaximumEnergyStorageCapacityExceeded] = useState(false);

    const newComponentsActivePowerAllowed = newActivePower <= oldActivePower;

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid:
            isValid &&
            isPlantComponentValid &&
            context.plantComponentsStorage?.length &&
            !maximumEnergyStorageCapacityExceeded &&
            newComponentsActivePowerAllowed,
        onBack: goBack,
        onNext: () => onSubmit(),
    });

    useEffect(() => {
        if (plantSizeIsAtMostOfSmallSize(connectionAssurance)) {
            setMaximumEnergyStorageCapacityExceeded(
                parseNumberFromString(context.storageData?.energyStorageCapacityInkWh) > 100,
            );
        }
    }, [context.storageData?.energyStorageCapacityInkWh, context, connectionAssurance]);

    useContextUpdate(watch, updateContext, 'PLANT_COMPONENTS_STORAGE');

    return (
        <Form title={title} leftButton={prevButton} rightButton={nextButton} handleSubmit={() => onSubmit()}>
            <FormSubTitle>Anlagenkomponenten des Energiespeichersystems</FormSubTitle>
            <p>
                Bitte prüfen Sie die Angaben auf Korrektheit und passen diese bei Bedarf an. Sie können die
                Wechselrichter verändern, wenn die Wirkleistung gleich bleibt.
            </p>
            <div className="flex flex-col">
                <FormProvider {...reactHookFormPlantComponents}>
                    <PlantComponentsTable
                        contextPlantComponents={context.plantComponentsStorage}
                        plantComponentType="plantComponentsStorage"
                        allPlantComponents={allPlantComponents}
                        updateContext={updateContext}
                    />
                </FormProvider>
                <FormProvider {...reactHookForm}>
                    <FormLayout>
                        <FormItem label="Wie hoch ist die nutzbare Speicherkapazität des Energiespeichers?">
                            <div className="flex gap-16">
                                <div className="flex gap-x-2 items-center">
                                    <div className="w-24">
                                        <TextField
                                            id="energy-storage-capacity"
                                            data-testid="energy-storage-capacity"
                                            tooltipClassNames="w-max"
                                            displayErrorMessage={
                                                errors.storageData?.energyStorageCapacityInkWh?.message
                                            }
                                            {...register('storageData.energyStorageCapacityInkWh', {
                                                validate: (capacity) => validateNumberInput(capacity),
                                                required: REQUIRED_ERROR_MESSAGE,
                                            })}
                                        />
                                    </div>
                                    <span>kWh</span>
                                </div>
                                {maximumEnergyStorageCapacityExceeded && <EnergyStorageCapacityErrorText />}
                            </div>
                        </FormItem>
                        <MasterDataRegisterNumberField showStorage />
                        {!isPlantComponentsGeneratorShown(context) && <ElectricityMeterSerialNumberField />}
                    </FormLayout>
                </FormProvider>
            </div>
            {!newComponentsActivePowerAllowed && (
                <div className="flex gap-x-4 bg-white p-4 shadow-ten" data-testid="error-active-power">
                    <div className="flex items-center w-20">
                        <TeagPiktoGaststoerung800X800210816 className="fill-current text-primary" />
                    </div>
                    <div className="flex items-center pr-4">
                        Die Änderungen ergeben eine Gesamtleistung von {formatNumber(newActivePower)} kW, diese Leistung
                        liegt über der zugesicherten Maximalleistung von {formatNumber(oldActivePower)} kW. Sollte die
                        bisher zugesicherte maximale Einspeiseleistung für die gewünschten Komponenten nicht ausreichen,
                        muss ein neuer Einspeisungsantrag gestellt werden.
                    </div>
                </div>
            )}
        </Form>
    );
};

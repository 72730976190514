import { MetaObject } from '@/connection-assurance/compensation/types/MetaObject';
import { mergeDeep } from '@/utils/object';
import { generatePath } from 'react-router-dom';
import { assign } from 'xstate';

import { Context } from './context';
import {
    UpdateContextEvent,
    UpdatePathEvent,
    UpdateWizardStepsEvent,
    UpdateWizardStepsVisibilityEvent,
    UserEvent,
} from './events';
import { setInitialStepVisibility, setWaiverStepVisibility } from './wizard';

export default {
    UPDATE_CONTEXT: assign<Context, UserEvent>((context: Context, event: UpdateContextEvent) =>
        mergeDeep(context, event.value),
    ),

    UPDATE_FURTHEST_STEPS: assign<Context, UpdateWizardStepsEvent>((context: Context, event, { action }) => {
        const { processSubprocessIndex } = action as unknown as UpdateWizardStepsEvent;

        const [positionOfFurthestProcess, positionOfFurthestSubProcess] = context.meta.furthestProcessSubProcessIndex;
        const [positionOfCurrentProcess, positionOfCurrentSubProcess] = processSubprocessIndex;

        if (
            positionOfFurthestProcess < positionOfCurrentProcess ||
            (positionOfFurthestProcess === positionOfCurrentProcess &&
                positionOfFurthestSubProcess < positionOfCurrentSubProcess)
        ) {
            return {
                ...(context as Partial<Context>),
                ...({
                    meta: {
                        ...context.meta,
                        furthestProcessSubProcessIndex: processSubprocessIndex,
                    },
                } as Partial<MetaObject>),
            };
        }
        return context;
    }),

    UPDATE_PATH: assign<Context, UpdatePathEvent>((context: Context, _, { action }) => {
        const { path } = action as unknown as UpdatePathEvent;
        return {
            ...(context as Partial<Context>),
            ...({
                meta: {
                    ...context.meta,
                    path,
                },
            } as Partial<MetaObject>),
        };
    }),

    UPDATE_WIZARD_STEPS_VISIBILITY: assign<Context, UpdateWizardStepsVisibilityEvent>((context: Context) => {
        if (context.compensationAgreed === 'yes') {
            setInitialStepVisibility(context);
        } else {
            setWaiverStepVisibility(context);
        }

        return context;
    }),

    SYNC_LOCATION_WITH_STATE: (context: Context): void => {
        context.meta.history.push(
            generatePath(context.meta.path, {
                connectionAssuranceId: context.connectionAssuranceId,
            }),
        );
    },
};

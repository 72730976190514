import { FieldPath, FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';

// Use this if you call register directly
export function registerWrapper<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
    register: UseFormRegister<TFieldValues>,
    fieldName: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>,
) {
    return { ...register(fieldName, options), required: !!options?.required };
}

// Use this if you need to pass the register function to a child component
export function wrappedRegister<TFieldValues extends FieldValues>(
    register: UseFormRegister<TFieldValues>,
): UseFormRegister<TFieldValues> {
    return (name, options) => ({
        ...register(name, options),
        required: !!options?.required,
    });
}

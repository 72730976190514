import {
    AddressLocation,
    OfflineLocationType,
    OfflineSaxonyAnhaltOrThuringiaCadastralDistrictLocation,
    OfflineSaxonyCadastralDistrictLocation,
} from '@/types/Location';

export type LowVoltageResponsibility = 'TEN' | 'Other' | 'OperatingSupply';
export type MediumVoltageAvailability = 'LineAvailable' | 'LineNotAvailable' | 'OperatingSupply';

export type ResponsibilityAndAvailabilityType = {
    responsibilityAndAvailability: {
        lowVoltageResponsibility: LowVoltageResponsibility;
        mediumVoltageAvailability: MediumVoltageAvailability;
    };
};

export type ConnectionAssuranceAddressLocation = {
    gisId: string;
    streetName: string;
    cityName: string;
    districtName: string;
    postcode: string;
    housenumber?: number;
    housenumberAddition?: string;
    geojson: AddressGeoCoordinates;
    epsgCode: string;
    cadastralDistrictCoordinates?: CadastralDistrictGeoCoordinates;
};

export type AddressGeoCoordinates = {
    type: 'Point';
    coordinates: number[];
};

export type Saxony = '14' | 'SN';
export type SaxonyAnhalt = '15' | 'ST';
export type Thuringia = '16' | 'TH';

// the codes 14 (SN), 15 (ST) and 16 (TH) are matching to the corresponding states
// the numeric codes are passed by the backend in the responses
export type StateCode = Saxony | SaxonyAnhalt | Thuringia;

export type ConnectionAssuranceCadastralDistrictLocation =
    | SaxonyCadastralDistrictLocation
    | SaxonyAnhaltOrThuringiaCadastralDistrictLocation;

type BaseCadastralDistrict = {
    districtId: string;
    districtName: string;
    geojson: CadastralDistrictGeoCoordinates;
    epsgCode: string;
    cadastralUnitCounter?: string | undefined | null;
    cadastralUnitDenominator?: string | undefined | null;
    cadastralDistrict?: null | string;
};

export type SaxonyCadastralDistrictLocation = BaseCadastralDistrict & {
    stateCode: Saxony;
};

export type SaxonyAnhaltOrThuringiaCadastralDistrictLocation = BaseCadastralDistrict & {
    stateCode: Exclude<StateCode, Saxony>;
    cadastralDistrict: string;
};

export type CadastralDistrictGeoCoordinates = {
    type: string;
    coordinates: number[][][];
};

export const isAddress = (
    location: ConnectionAssuranceLocation,
): location is ConnectionAssuranceAddressLocation & ResponsibilityAndAvailabilityType =>
    (location as ConnectionAssuranceAddressLocation).streetName !== undefined;

export const isOfflineAddress = (location: OfflineLocationType): location is AddressLocation & { type: 'address' } =>
    (location as AddressLocation).street !== undefined;

export const isOfflineSaxonyAnhaltOrThuringiaCadastralDistrict = (
    location: OfflineLocationType,
): location is OfflineSaxonyAnhaltOrThuringiaCadastralDistrictLocation & { type: 'cadastral' } =>
    (location as OfflineSaxonyAnhaltOrThuringiaCadastralDistrictLocation).cadastralDistrict !== undefined;

export const isOfflineSaxonyCadastralDistrict = (
    location: OfflineLocationType,
): location is OfflineSaxonyCadastralDistrictLocation & { type: 'cadastral' } =>
    (location as OfflineSaxonyCadastralDistrictLocation).stateCode === '14' ||
    (location as OfflineSaxonyCadastralDistrictLocation).stateCode === 'SN';

export function isSaxonyAnhaltOrThuringiaCadastralDistrictLocation(
    location: ConnectionAssuranceCadastralDistrictLocation,
): location is SaxonyAnhaltOrThuringiaCadastralDistrictLocation {
    return (
        location.stateCode === '15' ||
        location.stateCode === 'ST' ||
        location.stateCode === '16' ||
        location.stateCode === 'TH'
    );
}

export type ShortenedCadastralDistrict = Omit<ConnectionAssuranceCadastralDistrictLocation, 'geojson' | 'epsgCode'>;

export type ShortenedAddressLocation = Omit<
    ConnectionAssuranceAddressLocation,
    'districtName' | 'geojson' | 'epsgCode' | 'cadastralDistrictCoordinates'
>;

export type NumberOfLocation = 'one' | 'many';

export type ConnectionAssuranceLocation = (
    | ConnectionAssuranceAddressLocation
    | ConnectionAssuranceCadastralDistrictLocation
) &
    ResponsibilityAndAvailabilityType;

import { TelephoneData } from '@/types/PlantData';
import { flattenErrors } from '@/utils/flattenErrors';
import { registerWrapper } from '@/utils/formHookHelper';
import { REQUIRED_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE } from '@/utils/textConstants';
import { Dropdown, TextField, Tooltip } from '@ten-netzkundenportal/ui-components';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import telephoneNumberValidation from './telephoneNumberValidation';

type TelephoneNumberAreaProps = {
    registerPrefix: string;
};

const TelephoneNumberArea = ({ registerPrefix }: TelephoneNumberAreaProps): React.ReactElement => {
    const {
        register,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();

    const leadingZeroErrorMessage = 'Bitte geben Sie Ihre Telefonnummer ohne führende Null ein.';

    const dropdownOptions = {
        numberType: [
            {
                value: 'Mobile',
                label: 'Mobil',
            },
            {
                value: 'Landline',
                label: 'Festnetz',
            },
        ],
    };

    const telephoneCountryPrefix: string = watch(`${registerPrefix}.telephone.countryPrefix`);
    const telephoneNumber: string = watch(`${registerPrefix}.telephone.number`);
    const [isTrackingPhoneNumberAutocompletion, setIsTrackingPhoneNumberAutocompletion] = useState(true);
    useEffect(() => {
        if (isTrackingPhoneNumberAutocompletion) {
            // after autocompletion happened, the leading 0 should be removed for german numbers
            if (
                telephoneNumber &&
                telephoneNumber.length > 5 &&
                telephoneCountryPrefix === '49' &&
                telephoneNumber[0] === '0'
            ) {
                setValue(`${registerPrefix}.telephone.number`, telephoneNumber.slice(1));
                setIsTrackingPhoneNumberAutocompletion(false);
            }
            // while typing normally, nothing should be removed automatically
            else if (telephoneNumber && telephoneNumber.length > 0) {
                setIsTrackingPhoneNumberAutocompletion(false);
            }
        }
        // reset tracking of autocompletion changes if the input field has been emptied
        else if (telephoneNumber === '') {
            setIsTrackingPhoneNumberAutocompletion(true);
        }
    }, [setValue, telephoneNumber, isTrackingPhoneNumberAutocompletion, telephoneCountryPrefix, registerPrefix]);

    return (
        <div className="flex flex-row items-center space-x-2 max-w-[456px]">
            <div className="basis-1/4 text-small">
                <label htmlFor={`dropdown-${registerPrefix}.telephone.type`}>Art</label>
                <Dropdown
                    name={`${registerPrefix}.telephone.type`}
                    options={dropdownOptions.numberType}
                    dropdownClassname="w-full"
                    {...registerWrapper(register, `${registerPrefix}.telephone.type`, { required: true })}
                />
                {errors?.telephone?.type && (
                    <div>
                        <Tooltip message={REQUIRED_ERROR_MESSAGE} />
                    </div>
                )}
            </div>
            <div className="basis-1/4 min-w-[85px]">
                <TextField
                    autoComplete="tel-country-code"
                    name={`${registerPrefix}.telephone.countryPrefix`}
                    label="Ländervorwahl"
                    prefix="+"
                    displayErrorMessage={
                        flattenErrors<TelephoneData>(registerPrefix, errors).telephone?.countryPrefix?.message
                    }
                    {...registerWrapper(register, `${registerPrefix}.telephone.countryPrefix`, {
                        required: REQUIRED_ERROR_MESSAGE,
                        pattern: { value: /^\d+$/, message: VALIDATION_ERROR_MESSAGE },
                        maxLength: { value: 5, message: VALIDATION_ERROR_MESSAGE },
                    })}
                />
            </div>
            <div className="basis-1/2">
                <TextField
                    autoComplete="tel-national"
                    name={`${registerPrefix}.telephone.number`}
                    label="Nummer"
                    displayErrorMessage={
                        flattenErrors<TelephoneData>(registerPrefix, errors).telephone?.number?.message
                    }
                    {...registerWrapper(register, `${registerPrefix}.telephone.number`, {
                        required: REQUIRED_ERROR_MESSAGE,
                        validate: (value) =>
                            telephoneNumberValidation(value, VALIDATION_ERROR_MESSAGE, leadingZeroErrorMessage),
                    })}
                />
            </div>
        </div>
    );
};
export default TelephoneNumberArea;

import { WaiverAgreed } from '@/connection-assurance/compensation/types/CompensationData';
import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import { Checkbox, Form, FormSubTitle, createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { useContextUpdate } from '../wizard/hooks';

export const CompletionWaiver = ({
    goBack,
    onSubmit,
    title,
    updateContext,
}: RouteProperties & CompensationProcessProps) => {
    const { handleSubmit, register, watch, control } = useForm<WaiverAgreed>();

    useContextUpdate(watch, updateContext, 'COMPLETION');

    const waiverAgreed = useWatch({ control, name: 'waiverAgreed' });

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: waiverAgreed,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        nextLabel: 'Absenden',
    });

    return (
        <Form title={title} rightButton={nextButton} leftButton={prevButton} handleSubmit={handleSubmit(onSubmit)}>
            <FormSubTitle>Verzicht auf Einspeisevergütung</FormSubTitle>
            <div className="shadow-ten p-6 flex flex-col gap-y-6">
                <p className="font-bold text-subheading border-b-2 border-primary w-fit pe-4 pb-2">
                    Verzichtserklärung
                </p>
                <p>
                    Der Anlagenbetreiber verzichtet gegenüber der TEN als Netzbetreiber auf die Auszahlung der
                    Einspeisevergütung (finanzielle Förderung).
                </p>
                <p>
                    Diese Vereinbarung gilt maximal für die Dauer der gesetzlichen Förderung nach der jeweils geltenden
                    Fassung des EEG. Der Anlagenbetreiber kann die Vereinbarung schriftlich mit einer Frist von vier
                    Wochen zum Monatsende kündigen.
                </p>
                <p>
                    Im Falle einer Kündigung muss der Anlagenbetreiber die notwendigen Zählerstände für den laufenden
                    Abrechnungszeitraum (Monat/Jahr) unverzüglich an den Netzbetreiber übermitteln. Die Auszahlung der
                    Vergütung erfolgt nach dem Ende dieser Verzichtsvereinbarung und nur für die Einspeisungen ab diesem
                    Zeitpunkt. Es gibt keine Vergütungsansprüche für vergangene Zeiträume.
                </p>
            </div>

            <div className="bg-blue-50 flex justify-end p-5 gap-x-5">
                <p>
                    Hiermit bestätige ich, dass auf die Auszahlung einer Einspeisevergütung (finanzielle Förderung)
                    verzichtet werden soll.
                </p>
                <Checkbox
                    data-testid="checkbox-waiver-agreed"
                    name="check-waiverAgreed"
                    {...register('waiverAgreed')}
                />
            </div>
        </Form>
    );
};

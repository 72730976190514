import { CompensationAgreed } from '@/connection-assurance/compensation/types/CompensationData';
import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import {
    Form,
    FormItem,
    FormLayout,
    FormSubTitle,
    RadioButton,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { useContextUpdate } from '../wizard/hooks';

export const Compensation = ({
    goBack,
    onSubmit,
    context,
    updateContext,
    title,
}: RouteProperties & CompensationProcessProps) => {
    const { handleSubmit, register, watch, control } = useForm<CompensationAgreed>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            compensationAgreed: context.compensationAgreed || 'yes',
        },
    });

    useContextUpdate(watch, updateContext, 'PLANT_COMPENSATION_AGREEMENT');

    const compensationAgreed = useWatch({ control, name: 'compensationAgreed' });

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: true,
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        nextLabel: 'Weiter',
        prevLabel: 'Abbrechen',
    });

    return (
        <Form title={title} rightButton={nextButton} leftButton={prevButton} handleSubmit={handleSubmit(onSubmit)}>
            <FormSubTitle>Einspeisevergütung</FormSubTitle>
            <p>
                Sobald Strom aus der Erzeugungsanlage in das Netz der TEN als Netzbetreiber eingespeist wird, hat der
                Anlagenbetreiber Anspruch auf Förderung gegenüber der TEN, gemäß des Erneuerbaren-Energien-Gesetzes
                (EEG). Es besteht auch die Möglichkeit auf die Einspeisevergütung zu verzichten.
            </p>
            <FormLayout>
                <FormItem
                    label="Soll eine Vergütung für die eingespeiste Strommenge ausgezahlt werden?"
                    className="flex mb-8 justify-start w-full gap-x-24"
                >
                    <RadioButton
                        id="compensationAgreed-yes"
                        name="compensationAgreed"
                        value="yes"
                        data-testid="compensationAgreed-yes"
                        {...register('compensationAgreed', {
                            required: REQUIRED_ERROR_MESSAGE,
                        })}
                    >
                        ja
                    </RadioButton>
                    <RadioButton
                        id="compensationAgreed-no"
                        name="compensationAgreed"
                        value="no"
                        data-testid="compensationAgreed-no"
                        {...register('compensationAgreed', {
                            required: REQUIRED_ERROR_MESSAGE,
                        })}
                    >
                        nein
                    </RadioButton>
                </FormItem>
            </FormLayout>
            {compensationAgreed === 'yes' && (
                <div className="shadow-ten p-8 w-fit" data-testid="further-information-card">
                    <p className="mb-4">
                        Um die Einspeisevergütung zu prüfen und diese zeitnah auszahlen zu können, benötigen wir noch
                        Informationen von Ihnen:
                    </p>
                    <ul className="teagTickList flex flex-col gap-y-2 ms-8">
                        <li>EEG-Inbetriebnahmedatum der Erzeugungsanlage / des Energiespeichers</li>
                        <li>Registrierungsbestätigung im Marktstammdatenregister</li>
                        <li>Daten möglicher Bestandsanlagen und deren Zählerstand</li>
                        {(context.customerType === 'private' || context.customerType === 'company') && (
                            <li>Kontodaten sowie die Steuernummer</li>
                        )}
                    </ul>
                </div>
            )}
        </Form>
    );
};

import { TechnicalOperationsManagerInformation } from '@/types/technicalOperationsManager';
import { registerWrapper, wrappedRegister } from '@/utils/formHookHelper';
import { REQUIRED_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE } from '@/utils/textConstants';
import { AddressModule, CompanyNameModule, TelephoneNumberArea } from '@components/address';
import { EmailInputField, FormItem, TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export default () => {
    const {
        register,
        formState: { errors },
    } = useFormContext<TechnicalOperationsManagerInformation>();

    return (
        <>
            <FormItem label="Bitte geben Sie die Daten des technischen Betriebsführers an:">
                <div className="max-w-[456px]">
                    <CompanyNameModule registerPrefix="technicalOperationsManager" />
                    <AddressModule registerPrefix="technicalOperationsManager.address" />
                </div>
            </FormItem>

            <FormItem label="Bitte geben Sie uns einen Ansprechpartner für Rückfragen an:">
                <div className="max-w-[456px]">
                    <div className="flex space-x-2 h-20 ">
                        <div className="w-full">
                            <TextField
                                autoComplete="given-name"
                                label="Vorname"
                                displayErrorMessage={errors?.technicalOperationsManager?.contact?.firstName?.message}
                                {...registerWrapper(register, `technicalOperationsManager.contact.firstName`, {
                                    required: REQUIRED_ERROR_MESSAGE,
                                    minLength: { value: 2, message: VALIDATION_ERROR_MESSAGE },
                                    maxLength: { value: 20, message: VALIDATION_ERROR_MESSAGE },
                                })}
                            />
                        </div>
                        <div className="w-full h-20">
                            <TextField
                                autoComplete="family-name"
                                label="Nachname"
                                displayErrorMessage={errors?.technicalOperationsManager?.contact?.lastName?.message}
                                {...registerWrapper(register, `technicalOperationsManager.contact.lastName`, {
                                    required: REQUIRED_ERROR_MESSAGE,
                                    minLength: { value: 2, message: VALIDATION_ERROR_MESSAGE },
                                    maxLength: { value: 50, message: VALIDATION_ERROR_MESSAGE },
                                })}
                            />
                        </div>
                    </div>
                    <TelephoneNumberArea registerPrefix="technicalOperationsManager.contact" />

                    <div className="w-full h-20 my-6">
                        <EmailInputField
                            id="technicalOperationsManager.email"
                            register={wrappedRegister(register)}
                            displayErrorMessage={errors?.technicalOperationsManager?.email?.message}
                            requiredErrorMessage={REQUIRED_ERROR_MESSAGE}
                        />
                    </div>
                </div>
            </FormItem>
        </>
    );
};

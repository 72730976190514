import { TokenClaims } from '@/types/tokenClaims';
import { useMsal } from '@azure/msal-react';
import { AxiosInstance } from 'axios';
import React from 'react';

import useAuthenticatedHttpClient from './useAuthenticatedHttpClient';

export type ApiParams<T> = {
    params: T;
    customerId: string;
    installerId?: string;
    axios: AxiosInstance;
};

const useApi = <T, R>(fn: (params: ApiParams<T>) => Promise<R>) => {
    const { accounts } = useMsal();
    const axios = useAuthenticatedHttpClient();

    const call = React.useMemo(
        () => (params: T) =>
            fn({
                params,
                customerId: accounts[0]?.localAccountId,
                installerId: (accounts[0]?.idTokenClaims as TokenClaims)?.extension_installerId,
                axios,
            }),
        [accounts, axios, fn],
    );

    return call;
};

export default useApi;

import { RouteProperties } from '@/connection-assurance/compensation/types/RouteProperties';
import React from 'react';

import { CompensationProcessProps } from '../CompensationProcessProps';
import { CompletionConfirmation } from './CompletionConfirmation';
import { CompletionWaiver } from './CompletionWaiver';

export const Completion = (props: RouteProperties & CompensationProcessProps) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.context.compensationAgreed === 'yes') {
        return <CompletionConfirmation {...props} />;
    }
    return <CompletionWaiver {...props} />;
};

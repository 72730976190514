import { ColumnItem } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { ConnectionColumnProps } from './ConnectionColumn';

export const ElectricityMeterColumn = ({ connectionData }: Pick<ConnectionColumnProps, 'connectionData'>) => (
    <>
        {!!connectionData.electricityMeterSerialNumber && (
            <ColumnItem label="Zählernummer" text={connectionData.electricityMeterSerialNumber} />
        )}
        {connectionData.electricityMeterInspection?.inspectionText && (
            <ColumnItem label="Zählerprüfung" text={connectionData.electricityMeterInspection?.inspectionText} />
        )}
    </>
);
